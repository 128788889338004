import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data/api';

import { authApiPaths } from '../paths';
import { useSendCompleteSetupEmailData } from './useSendCompleteSetupEmail.types';

export const useSendCompleteSetupEmail = (
    mutationOptions?: MutationOptions<any, RequestDataParams<useSendCompleteSetupEmailData>>
) => {
    return useMutate<RequestDataParams<useSendCompleteSetupEmailData>>(authApiPaths.completeSetupEmail, {
        apiVersion: 'v2',
        mutationOptions,
    });
};
