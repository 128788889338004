import { Reference } from '@approvalmax/types';
import { CamelCasedPropertiesDeep } from 'type-fest';

import { domain } from '..';
import { EmailToSupplier, RequestAttachment } from '.';
import { BaseRequest } from './RequestBase';
import { TemplateSettingsLockDatePolicy } from './TemplateSettings';

export enum NetSuiteObjectStatus {
    Pending = 'Pending Approval',
    Rejected = 'Rejected',
    Open = 'Open',
    Paid = 'Paid In Full',
    Cancelled = 'Cancelled',
    FullyBilled = 'Fully Billed',
}

export enum NetSuiteVRAStatus {
    Closed = 'Closed',
    PendingReturn = 'PendingReturn',
    Credited = 'Credited',
    PendingCredit = 'PendingCredit',
}

export enum NetSuiteExpenseReportStatus {
    Unknown = 'unknown',
    ApprovedByAccounting = 'approved-by-accounting',
    ApprovedOverriddenByAccounting = 'approved-overridden-by-accounting',
    InProgress = 'in-progress',
    InTransit = 'in-transit',
    PaidInFull = 'paid-in-full',
    PendingAccountingApproval = 'pending-accounting-approval',
    PendingSupervisorApproval = 'pending-supervisor-approval',
    RejectedByAccounting = 'rejected-by-accounting',
    RejectedBySupervisor = 'rejected-by-supervisor',
    RejectedOverriddenByAccounting = 'rejected-overridden-by-accounting',
    Voided = 'voided',
}

export enum NetSuiteExpenseReportApprovalStatus {
    Unknown = 'unknown',
    PendingApproval = 'pending-approval',
    Approved = 'approved',
    Rejected = 'rejected',
}

interface ReferenceBackend {
    Id: string;
    Name: string;
}

interface BillingAddressBackend {
    Id: string;
    FullAddress: string;
}

export interface NetSuiteCustomFieldBackend {
    CompanyFieldId: string;
    ScriptId: string;
    InternalId: string;
    FieldName: string;
    FieldOrder: number;
    FieldType: string;
    ValueString: string | null;
    ValueBoolean: boolean | null;
    ValueDate: string | null;
    ValueDecimal: number | null;
    ValueLong: number | null;
    ValueRef: ReferenceBackend | null;
    ValueRefs: ReferenceBackend[] | null;
}

export interface NetSuiteItemLineBackend {
    Item: ReferenceBackend | null;
    VendorName: string | null;
    Quantity: number | null;
    Units: ReferenceBackend | null;
    Description: string;
    UnitPrice: number | null;
    CatalogUnitPrice: number | null;
    Customer: ReferenceBackend | null;
    IsClosed: boolean | null;
    Amount: number | null;
    TaxCode: ReferenceBackend;
    TaxRate: number | null;
    TaxAmount: number | null;
    GrossAmount: number | null;
    Department: ReferenceBackend | null;
    Class: ReferenceBackend | null;
    Location: ReferenceBackend | null;
    LineNumber: number;
    IsBillable: boolean;
    ExpectedReceiptDate?: string | null;
    CustomFields: NetSuiteCustomFieldBackend[];
    AmortizationStartDate?: string | null;
    AmortizationEndDate?: string | null;
    AmortizationResidual?: number | null;
    AmortizationSchedule?: ReferenceBackend | null;
}

export interface NetSuiteExpenseLineBackend {
    CatalogAmount: number | null;
    Category: ReferenceBackend | null;
    Account: ReferenceBackend | null;
    Memo: string | null;
    Customer: ReferenceBackend | null;
    Amount: number | null;
    TaxCode: ReferenceBackend | null;
    TaxRate: number | null;
    TaxAmount: number | null;
    GrossAmount: number | null;
    Department: ReferenceBackend | null;
    Class: ReferenceBackend | null;
    Location: ReferenceBackend | null;
    LineNumber: number;
    IsBillable: boolean;
    CustomFields: NetSuiteCustomFieldBackend[];
    AmortizationStartDate?: string | null;
    AmortizationEndDate?: string | null;
    AmortizationResidual?: number | null;
    AmortizationSchedule?: ReferenceBackend | null;
    OrderDocId: string | null;
    OrderLineNumber: number | null;
}

export interface NetSuiteVendorBillDetailsBackend {
    InternalId: string | null;
    TransactionNumber: string | null;
    Reference: string | null;
    Vendor: ReferenceBackend | null;
    TotalAmount: number | null;
    NetAmount: number | null;
    Currency: ReferenceBackend | null;
    ExchangeRate: number | null;
    VatRegistrationNumber: string | null;
    TaxAmount: number | null;
    DiscountAmount: number | null;
    DiscountDate: string | null;
    IsPaymentHold: boolean | null;
    DueDate: string | null;
    TransactionDate: string | null;
    Memo: string | null;
    PostingPeriod: ReferenceBackend | null;
    ApprovalStatus: string | null;
    Status: string | null;
    Subsidiary: ReferenceBackend | null;
    Terms: ReferenceBackend | null;
    Address: BillingAddressBackend | null;
    CreatedDate: string | null;
    ModifiedDate: string | null;
    IntegrationEnabled: boolean | null;
    WorkflowLockDatePolicy: TemplateSettingsLockDatePolicy | null;
    CompanyLockDate: string | null;
    Url: string | null;
    ItemLines: NetSuiteItemLineBackend[] | null;
    ExpenseLines: NetSuiteExpenseLineBackend[] | null;
    CustomFields: NetSuiteCustomFieldBackend[];
    Account: ReferenceBackend | null;
    Department: ReferenceBackend | null;
    Class: ReferenceBackend | null;
    Location: ReferenceBackend | null;
}

export interface NetSuitePurchaseOrderDetailsBackend {
    InternalId: string | null;
    VendorReference: string | null;
    Vendor: ReferenceBackend | null;
    Employee: ReferenceBackend | null;
    DueDate: string | null;
    TransactionDate: string | null;
    Reference: string | null;
    Subsidiary: ReferenceBackend | null;
    Class: ReferenceBackend | null;
    Location: ReferenceBackend | null;
    Department: ReferenceBackend | null;
    Currency: ReferenceBackend | null;
    ExchangeRate: number | null;
    ApprovalStatus: string | null;
    Status: string | null;
    Address: BillingAddressBackend | null;
    Terms: ReferenceBackend | null;
    Incoterm: ReferenceBackend | null;
    VatRegistrationNumber: string | null;
    Subtotal: number | null;
    TaxAmount: number | null;
    TotalAmount: number | null;
    NetAmount: number | null;
    Memo: string | null;
    CreatedDate: string | null;
    ModifiedDate: string | null;
    IntegrationEnabled: boolean | null;
    WorkflowLockDatePolicy: TemplateSettingsLockDatePolicy | null;
    CompanyLockDate: string | null;
    Url: string | null;
    ItemLines: NetSuiteItemLineBackend[] | null;
    ExpenseLines: NetSuiteExpenseLineBackend[] | null;
    CustomFields: NetSuiteCustomFieldBackend[];
}

export interface NetSuiteSalesOrderItemLineBackend {
    LineUniqueKey: number | null;
    LineNumber: number | null;
    Item: ReferenceBackend | null;
    QuantityCommitted: number | null;
    QuantityFulfilled: number | null;
    QuantityBilled: number | null;
    QuantityBackOrdered: number | null;
    Quantity: number | null;
    Units: ReferenceBackend | null;
    Description: string | null;
    SerialNumbers: string | null;
    PriceLevel: ReferenceBackend | null;
    UnitPrice: number | null;
    OrderPriority: number | null;
    RevenueRecognitionSchedule: ReferenceBackend | null;
    RevenueRecognitionStartDate: string | null;
    RevenueRecognitionEndDate: string | null;
    ExcludeFromRateRequest: boolean | null;
    IsClosed: boolean | null;
    IsBillable: boolean | null;
    TaxCode: ReferenceBackend | null;
    TaxRate: number | null;
    TaxAmount: number | null;
    Amount: number | null;
    Class: ReferenceBackend | null;
    Department: ReferenceBackend | null;
    Location: ReferenceBackend | null;
    CustomFields: NetSuiteCustomFieldBackend[];
}

export interface NetSuiteSalesOrderDetailsBackend {
    InternalId: string | null;
    Reference: string | null;
    Customer: ReferenceBackend | null;
    TransactionDate: string | null;
    StartDate: string | null;
    EndDate: string | null;
    PaymentReference: string | null;
    Memo: string | null;
    ApprovalStatus: string | null;
    Status: string | null;
    SalesRep: ReferenceBackend | null;
    Opportunity: ReferenceBackend | null;
    SalesEffectiveDate: string | null;
    ExcludeCommissions: boolean | null;
    Partner: ReferenceBackend | null;
    Class: ReferenceBackend | null;
    Department: ReferenceBackend | null;
    Location: ReferenceBackend | null;
    DiscountItem: ReferenceBackend | null;
    DiscountRate: number | null;
    IsDiscountRateAbsolute: boolean | null;
    DiscountTotal: number | null;
    Subtotal: number | null;
    TaxTotal: number | null;
    Total: number | null;
    ShipDate: string | null;
    ShipMethod: ReferenceBackend | null;
    ShipComplete: boolean | null;
    ShippingAddress: BillingAddressBackend | null;
    Terms: ReferenceBackend | null;
    BillingSchedule: ReferenceBackend | null;
    Address: BillingAddressBackend | null;
    PaymentMethod: ReferenceBackend | null;
    CreditCardNumber: string | null;
    CreditCardExpirationDate: string | null;
    CreditCardCardholderName: string | null;
    CreditCardStreet: string | null;
    CreditCardZipCode: string | null;
    CreditCardProcessor: ReferenceBackend | null;
    CreditCardApproved: boolean | null;
    PnRef: string | null;
    AuthCode: string | null;
    AvsStreetMatch: string | null;
    AvsZipMatch: string | null;
    Currency: ReferenceBackend | null;
    ExchangeRate: number | null;
    VatRegistrationNumber: string | null;
    RevenueStatus: string | null;
    RecognizedRevenue: number | null;
    DeferredRevenue: number | null;
    Url: string | null;
    ItemLines: NetSuiteSalesOrderItemLineBackend[];
    CustomFields: NetSuiteCustomFieldBackend[];
}

export interface NetSuiteVRADetailsBackend {
    InternalId: string;
    TransactionNumber: string | null;
    Vendor: ReferenceBackend | null;
    TotalAmount: number | null;
    Currency: ReferenceBackend | null;
    ExchangeRate: number | null;
    TaxAmount: number | null;
    TransactionDate: string;
    Memo: string | null;
    Status: string | null;
    Subsidiary: ReferenceBackend | null;
    BillingAddress: BillingAddressBackend | null;
    CreatedDate: string;
    ModifiedDate: string;
    IntegrationEnabled: boolean;
    WorkflowLockDatePolicy: TemplateSettingsLockDatePolicy | null;
    CompanyLockDate: string | null;
    Url: string | null;
    CustomFields: NetSuiteCustomFieldBackend[];
    ItemLines: NetSuiteItemLineBackend[];
    ExpenseLines: NetSuiteExpenseLineBackend[];
    NetAmount: number | null;
    Department: ReferenceBackend | null;
    Class: ReferenceBackend | null;
    Location: ReferenceBackend | null;
}

export interface NetSuiteBillPaymentApplyListItemBackend {
    AmountOnApproval: number;
    AmountDue: number | null;
    CurrencyName: string | null;
    DateDue: string | null;
    DiscountAmount: number | null;
    DiscountAvailable: number | null;
    DiscountDate: string | null;
    LineNumber: number | null;
    OriginalAmount: number | null;
    PaymentAmount: number | null;
    Reference: string | null;
    TransactionId: string | null;
    Type: string | null;
}

export interface NetSuiteBillPaymentCreditListItemBackend {
    Reference: string | null;
    AppliedTo: string | null;
    Type: string | null;
    DateDue: string | null;
    Total: number | null;
    LineNumber: number | null;
    TransactionId: string | null;
    CurrencyName: string | null;
    PaymentAmount: number | null;
}

export interface NetSuiteBillPaymentDetailsBackend {
    Payee: Reference;
    Account: Reference;
    Currency: Reference;
    Department: Reference;
    Class: Reference;
    Location: Reference;
    PayeeAddress: NetSuitePayeeAddress;
    PostingPeriod: Reference | null;
    TransactionNumber: string | null;
    TransactionDate: string | null;
    Balance: number | null;
    Amount: number | null;
    ExchangeRate: number | null;
    ToBePrinted: boolean | null;
    PrintVoucher: boolean | null;
    CheckNumber: string | null;
    Memo: string | null;
    Url: string | null;
    ApprovalStatus: string | null;
    Status: string | null;
    CreatedDate: string | null;
    ModifiedDate: string | null;
    CreditList: NetSuiteBillPaymentCreditListItemBackend[];
    ApplyList: NetSuiteBillPaymentApplyListItemBackend[];
    CustomFields: NetSuiteCustomFieldBackend[] | null;
}

export interface NetSuiteInvoiceItemListItemBackend {
    Item: Reference | null;
    Units: Reference | null;
    PriceLevel: Reference | null;
    Quantity: number | null;
    Description: string | null;
    UnitPrice: number | null;
    Amount: number | null;
    TaxCode: Reference | null;
    TaxRate: number | null;
    TaxAmount: number | null;
    GrossAmount: number | null;
    Department: Reference | null;
    Class: Reference | null;
    Location: Reference | null;
    CustomFields?: NetSuiteCustomFieldBackend[];
}

export interface NetSuiteInvoiceExpenseCostListItemBackend {
    Job: string | null;
    Employee: string | null;
    BillDate: string | null;
    Amount: number | null;
    TaxRate: number | null;
    TaxRate2: number | null;
    TaxAmount: number | null;
    Tax2Amount: number | null;
    GrossAmount: number | null;
    Department: string | null;
    Location: string | null;
    Class: string | null;
}

export interface NetSuiteInvoiceItemCostListItemBackend {
    Job: string | null;
    Employee: string | null;
    BilledDate: string | null;
    Amount: number | null;
    TaxRate: number | null;
    TaxRate2: number | null;
    TaxAmount: number | null;
    Tax2Amount: number | null;
    GrossAmount: number | null;
    Department: string | null;
    Location: string | null;
    Class: string | null;
    Quantity: number | null;
}

export interface NetSuiteInvoiceTimeListItemBackend {
    Job: string | null;
    Employee: string | null;
    BilledDate: string | null;
    Amount: number | null;
    TaxRate: number | null;
    TaxRate2: number | null;
    TaxAmount: number | null;
    Tax2Amount: number | null;
    GrossAmount: number | null;
    Department: string | null;
    Location: string | null;
    Class: string | null;
    Quantity: number | null;
    TaxCodeName: string | null;
    Unit: string | null;
    Rate: number | null;
    Item: string | null;
}

export interface NetSuiteInvoiceDetailsBackend {
    Class: Reference;
    Currency: Reference;
    Department: Reference;
    DiscountItem: Reference;
    Customer: Reference;
    LeadSource: Reference;
    Location: Reference;
    Opportunity: Reference;
    Partner: Reference;
    PostingPeriod: Reference;
    ShipMethod: Reference;
    Terms: Reference;
    SalesRep: Reference;
    TransactionDate: string;
    Subsidiary: Reference;
    CreatedDate: string;
    ModifiedDate: string;
    CompanyId: string;
    Date: string;
    InternalId: string | null;
    BillingAddressId: string | null;
    BillingAddressFullAddress: string | null;
    DiscountRate: string | null;
    DiscountTotal: number | null;
    DueDate: string | null;
    EndDate: string | null;
    ExchangeRate: number | null;
    ExcludeCommissions: boolean | null;
    LastModifiedDate: string | null;
    Memo: string | null;
    TransactionReference: string | null;
    RecognizedRevenue: number | null;
    RevenueStatus: number | null;
    SalesEffectiveDate: string | null;
    ShipDate: string | null;
    StartDate: string | null;
    Status: string | null;
    SubTotal: number | null;
    TaxTotal: number | null;
    Total: number | null;
    TransactionNumber: string | null;
    VatRegistrationNumber: string | null;
    Url: string | null;
    ItemList: NetSuiteInvoiceItemListItemBackend[];
    ExpenseCostList: NetSuiteInvoiceExpenseCostListItemBackend[];
    ItemCostList?: NetSuiteInvoiceItemCostListItemBackend[];
    InvoiceTimeList?: NetSuiteInvoiceTimeListItemBackend[];
    CustomFields?: NetSuiteCustomField[];
}

export interface NetSuiteBillPaymentCreditListItem {
    reference: string | null;
    appliedTo: string | null;
    type: string | null;
    dateDue: string | null;
    total: number | null;
    lineNumber: number | null;
    transactionId: string | null;
    currencyName: string | null;
    paymentAmount: number | null;
}

export interface NetSuiteCustomField {
    companyFieldId: string;
    scriptId: string;
    internalId: string;
    fieldType: string | null;
    valueString: string | null;
    valueRef: Reference | null;
    valueRefs: Reference[] | null;
    valueBoolean: boolean | null;
    valueDate: string | null;
    valueDecimal: number | null;
    valueLong: number | null;
}

export interface BillingAddress {
    id: string;
    fullAddress: string | null;
}

export interface NetSuitePayeeAddress {
    Id: string;
    AddressText: string;
}

export interface NetSuiteSharedDetails {
    date: string | null;
    dueDate: string | null;
    reference: string | null;
    address: BillingAddress | null;
    url: string | null;
    netAmount: number;
    taxAmount: number;
    totalAmount: number;
    currency: Reference | null;
    exchangeRate: number | null;
    vatRegistrationNumber: string | null;
    lockDate: string | null;
    lockDatePolicy: TemplateSettingsLockDatePolicy;
    status: NetSuiteObjectStatus | null;
    transactionDate: string | null;
    customFields?: NetSuiteCustomField[];
    number: string;
}

interface NetSuiteLine {
    amount: number | null;
    class: Reference | null;
    customer: Reference | null;
    department: Reference | null;
    grossAmount: number | null;
    isBillable: boolean | null;
    lineNumber: number;
    location: Reference | null;
    taxAmount: number | null;
    taxCode: Reference | null;
    taxRate: number | null;
    customFields?: NetSuiteCustomField[];
    amortizationStartDate?: string | null;
    amortizationEndDate?: string | null;
    amortizationResidual?: number | null;
    amortizationSchedule: Reference | null;
    orderDocId?: string | null;
    orderLineNumber?: number | null;
}

export interface NetSuiteExpenseLine extends NetSuiteLine {
    account: Reference | null;
    category: Reference | null;
    memo: string | null;
    defaultRate?: number | null;
    rates?: {
        currency: string;
        defaultRate: number | null;
    }[];
}

export interface NetSuiteExpenseLineExpenseReport {
    lineNumber: number;
    amount: number | null;
    category: Reference | null;
    class: Reference | null;
    currency: Reference | null;
    customFields?: NetSuiteCustomField[];
    customer: Reference | null;
    department: Reference | null;
    exchangeRate: number | null;
    expenseAccount: Reference | null;
    expenseDate: string | null;
    foreignAmount: number | null;
    grossAmount: number | null;
    attachment: RequestAttachment | null;
    isBillable: boolean;
    isCorporateCreditCard: boolean;
    isNonReimbursable: boolean;
    isReceipt: boolean;
    location: Reference | null;
    memo: string | null;
    quantity: number | null;
    refLineNumber: number | null;
    taxAmount: number | null;
    taxCode: Reference | null;
    taxRate: number | null;
    unitPrice: number | null;
}

export interface NetSuiteUnitItem extends Reference {
    catalogUnitPrice: number | null;
    isItemPrimaryPurchaseUnit: boolean;
}

export interface NetSuiteItemLine extends NetSuiteLine {
    description: string | null;
    item: Reference | null;
    quantity: number | null;
    unitPrice: number | null;
    units: Reference | null;
    vendorName: string | null;
    expectedReceiptDate?: string | null;
    isClosed: boolean | null;
    defaultRate?: number | null;
    unitList?: NetSuiteUnitItem[] | null;
}

export interface NetSuiteBillPaymentApplyListItem {
    lineNumber: number | null;
    amountOnApproval: number;
    amountDue: number | null;
    currencyName: string | null;
    dateDue: string | null;
    discountAmount: number | null;
    discountAvailable: number | null;
    discountDate: string | null;
    originalAmount: number | null;
    paymentAmount: number | null;
    reference: string | null;
    transactionId: string | null;
    type: string | null;
}

export interface NetSuiteVendorBillDetails extends NetSuiteSharedDetails {
    integrationCode: domain.IntegrationCode.NetSuiteBill;
    integrationType: domain.IntegrationType.NetSuite;
    memo: string | null;
    transactionNumber: string | null;
    vendor: Reference | null;
    isPaymentHold: boolean | null;
    discountAmount: number | null;
    discountDate: string | null;
    postingPeriod: Reference | null;
    terms: Reference | null;
    account: Reference | null;
    department: Reference | null;
    class: Reference | null;
    location: Reference | null;
    subsidiary: Reference | null;
    expenseLines: NetSuiteExpenseLine[];
    itemLines: NetSuiteItemLine[];
}

export interface NetSuitePurchaseOrderDetails extends NetSuiteSharedDetails {
    integrationCode: domain.IntegrationCode.NetSuitePO;
    integrationType: domain.IntegrationType.NetSuite;
    vendor: Reference | null;
    vendorReference: string | null;
    terms: Reference | null;
    memo: string | null;
    employee: Reference | null;
    class: Reference | null;
    location: Reference | null;
    department: Reference | null;
    incoterm: Reference | null;
    subsidiary: Reference | null;
    expenseLines: NetSuiteExpenseLine[];
    itemLines: NetSuiteItemLine[];
    emailToSupplier: EmailToSupplier | null;
}

export interface NetSuiteExpenseReportDetails {
    integrationCode: domain.IntegrationCode.NetSuiteExpenseReport;
    integrationType: domain.IntegrationType.NetSuite;

    account: Reference | null;
    accountForCorporateCardExpenses: Reference | null;
    advance: number | null;
    approvalStatus: NetSuiteExpenseReportApprovalStatus | null;
    class: Reference | null;
    createdDate: string | null;
    currency: Reference | null;
    customFields?: NetSuiteCustomField[];
    date: string | null;
    department: Reference | null;
    dueDate: string | null;
    employee: Reference | null;
    exchangeRate: number | null;
    expenseLines: NetSuiteExpenseLineExpenseReport[];
    internalId: string | null;
    location: Reference | null;
    memo: string | null;
    modifiedDate: string | null;
    postingPeriod: Reference | null;
    reimbursableAmount: number | null;
    status: NetSuiteExpenseReportStatus | null;
    subsidiary: Reference | null;
    taxAmount: number | null;
    totalAmount: number | null;
    transactionNumber: string | null;
    url: string | null;
    useMultiCurrency: boolean;
}

export interface NetSuiteSalesOrderItem {
    lineUniqueKey: number | null;
    lineNumber: number | null;
    item: Reference | null;
    quantityCommitted: number | null;
    quantityFulfilled: number | null;
    quantityBilled: number | null;
    quantityBackOrdered: number | null;
    quantity: number | null;
    units: Reference | null;
    description: string | null;
    serialNumbers: string | null;
    priceLevel: Reference | null;
    unitPrice: number | null;
    amount: number | null;
    taxCode: Reference | null;
    taxRate: number | null;
    taxAmount: number | null;
    department: Reference | null;
    class: Reference | null;
    location: Reference | null;
    orderPriority: number | null;
    revenueRecognitionSchedule: Reference | null;
    revenueRecognitionStartDate: string | null;
    revenueRecognitionEndDate: string | null;
    excludeFromRateRequest: boolean;
    isClosed: boolean;
    isBillable: boolean;
    customFields?: NetSuiteCustomField[];
}

export interface NetSuiteSalesOrderItemWithId extends NetSuiteSalesOrderItem {
    id: string;
}

export interface NetSuiteInvoiceItemListItem
    extends CamelCasedPropertiesDeep<Omit<NetSuiteInvoiceItemListItemBackend, 'CustomFields'>> {
    customFields?: NetSuiteCustomField[];
}

export interface NetSuiteInvoiceExpenseCostListItem
    extends CamelCasedPropertiesDeep<NetSuiteInvoiceExpenseCostListItemBackend> {}

export interface NetSuiteInvoiceItemCostListItem
    extends CamelCasedPropertiesDeep<NetSuiteInvoiceItemCostListItemBackend> {}

export interface NetSuiteInvoiceTimeListItem extends CamelCasedPropertiesDeep<NetSuiteInvoiceTimeListItemBackend> {}

export interface NetSuiteSalesOrderDetails {
    integrationCode: domain.IntegrationCode.NetSuiteSalesOrder;
    integrationType: domain.IntegrationType.NetSuite;

    // Sales Order Details
    customer: Reference | null;
    address: BillingAddress | null;
    vatRegistrationNumber: string | null;
    reference: string;
    paymentReference: string | null;
    transactionDate: string | null;
    startDate: string | null;
    endDate: string | null;
    class: Reference | null;
    department: Reference | null;
    location: Reference | null;
    terms: Reference | null;
    billingSchedule: Reference | null;
    discountItem: Reference | null;
    discountRate: number | null;
    isDiscountRateAbsolute: boolean;
    memo: string | null;
    approvalStatus: string | null;
    status: string | null;
    customFields?: NetSuiteCustomField[];

    // Sales Details
    salesRep: Reference | null;
    opportunity: Reference | null;
    salesEffectiveDate: string | null;
    excludeCommissions: boolean;
    partner: Reference | null;

    // Shipping Details
    shipDate: string | null;
    shipMethod: Reference | null;
    shipComplete: boolean;
    shippingAddress: BillingAddress | null;

    // Revenue Details
    revenueStatus: string | null;
    recognizedRevenue: number | null;
    deferredRevenue: number | null;

    // Payment Details
    paymentMethod: Reference | null;
    creditCardNumber: string | null;
    creditCardExpirationDate: string | null;
    creditCardCardholderName: string | null;
    creditCardStreet: string | null;
    creditCardZipCode: string | null;
    creditCardProcessor: Reference | null;
    creditCardApproved: boolean;
    pnRef: string | null;
    authCode: string | null;
    avsStreetMatch: string | null;
    avsZipMatch: string | null;

    discountTotal: number | null;
    subTotal: number | null;
    taxAmount: number | null;
    totalAmount: number | null;
    currency: Reference | null;
    exchangeRate: number | null;
    url: string | null;
    itemLines: NetSuiteSalesOrderItem[];
}

export interface NetSuiteVRADetails {
    integrationCode: domain.IntegrationCode.NetSuiteVRA;
    integrationType: domain.IntegrationType.NetSuite;

    number: string | null;
    transactionNumber: string | null;
    vendor: Reference | null;
    totalAmount: number | null;
    currency: Reference | null;
    exchangeRate: number | null;
    taxAmount: number | null;
    transactionDate: string;
    memo: string | null;
    status: string | null;
    address: BillingAddress | null;
    vatRegistrationNumber?: string | null;
    lockDatePolicy: TemplateSettingsLockDatePolicy | null;
    lockDate: string | null;
    url: string | null;
    netAmount: number | null;
    department: Reference | null;
    class: Reference | null;
    location: Reference | null;
    itemLines: NetSuiteItemLine[];
    expenseLines: NetSuiteExpenseLine[];
    customFields: NetSuiteCustomField[];
}

export interface NetSuiteBillPaymentDetails {
    integrationCode: domain.IntegrationCode.NetSuiteBillPayment;
    integrationType: domain.IntegrationType.NetSuite;

    applyList: NetSuiteBillPaymentApplyListItem[];
    creditList: NetSuiteBillPaymentCreditListItem[];
    totalAmount: number;
    taxAmount: null;
    payeeAddress: string | null;
    currency: Reference | null;
    payee: Reference | null;
    account: Reference | null;
    class: Reference | null;
    department: Reference | null;
    location: Reference | null;
    postingPeriod: Reference | null;
    amount: number | null;
    transactionNumber: string | null;
    transactionDate: string | null;
    approvalStatus: string | null;
    balance: number | null;
    checkNumber: string | null;
    createdDate: string | null;
    exchangeRate: number | null;
    memo: string | null;
    modifiedDate: string | null;
    printVoucher: boolean | null;
    status: string | null;
    toBePrinted: boolean | null;
    url: string | null;
    customFields?: NetSuiteCustomField[];
}

export interface NetSuiteRADetails {
    integrationCode: domain.IntegrationCode.NetSuiteRA;
    integrationType: domain.IntegrationType.NetSuite;
}

export interface NetSuiteInvoiceDetails {
    integrationCode: domain.IntegrationCode.NetSuiteInvoice;
    integrationType: domain.IntegrationType.NetSuite;

    taxAmount: number | null;
    totalAmount: number | null;
    billingAddressId: string | null;
    billingAddressFullAddress: string | null;
    class: Reference | null;
    currency: Reference | null;
    department: Reference | null;
    discountItem: Reference | null;
    discountRate: string | null;
    discountTotal: number | null;
    dueDate: string | null;
    endDate: string | null;
    customer: Reference | null;
    exchangeRate: number | null;
    excludeCommissions: boolean | null;
    leadSource: Reference | null;
    location: Reference | null;
    memo: string | null;
    opportunity: Reference | null;
    transactionReference: string | null;
    partner: Reference | null;
    postingPeriod: Reference | null;
    recognizedRevenue: number | null;
    revenueStatus: number | null;
    salesEffectiveDate: string | null;
    shipDate: string | null;
    shipMethod: Reference | null;
    startDate: string | null;
    status: string | null;
    subTotal: number | null;
    terms: Reference | null;
    transactionNumber: string | null;
    vatRegistrationNumber: string | null;
    salesRep: Reference | null;
    transactionDate: string | null;
    subsidiary: Reference | null;
    companyId: string | null;
    date: string | null;
    url: string | null;
    itemList: NetSuiteInvoiceItemListItem[];
    expenseCostList: NetSuiteInvoiceExpenseCostListItem[];
    itemCostList: NetSuiteInvoiceItemCostListItem[];
    invoiceTimeList: NetSuiteInvoiceTimeListItem[];
    customFields?: NetSuiteCustomField[];
}

export type NetSuiteRequestDetails =
    | NetSuiteVendorBillDetails
    | NetSuitePurchaseOrderDetails
    | NetSuiteExpenseReportDetails
    | NetSuiteSalesOrderDetails
    | NetSuiteVRADetails
    | NetSuiteBillPaymentDetails
    | NetSuiteInvoiceDetails;

export type NetSuiteBillRequestSpecifics = {
    integrationType: domain.IntegrationType.NetSuite;
    integrationCode: domain.IntegrationCode.NetSuiteBill;
    details: NetSuiteVendorBillDetails;
};

export type NetSuiteBillRequest = BaseRequest & NetSuiteBillRequestSpecifics;

export type NetSuitePoRequestSpecifics = {
    integrationType: domain.IntegrationType.NetSuite;
    integrationCode: domain.IntegrationCode.NetSuitePO;
    details: NetSuitePurchaseOrderDetails;
};

export type NetSuitePoRequest = BaseRequest & NetSuitePoRequestSpecifics;

export type NetSuiteSalesOrderRequestSpecifics = {
    integrationType: domain.IntegrationType.NetSuite;
    integrationCode: domain.IntegrationCode.NetSuiteSalesOrder;
    details: NetSuiteSalesOrderDetails;
};

export type NetSuiteSalesOrderRequest = BaseRequest & NetSuiteSalesOrderRequestSpecifics;

export type NetSuiteExpenseReportRequestSpecifics = {
    integrationType: domain.IntegrationType.NetSuite;
    integrationCode: domain.IntegrationCode.NetSuiteExpenseReport;
    details: domain.NetSuiteExpenseReportDetails;
};

export type NetSuiteExpenseReportRequest = BaseRequest & NetSuiteExpenseReportRequestSpecifics;

export type NetSuiteVRARequestSpecifics = {
    integrationType: domain.IntegrationType.NetSuite;
    integrationCode: domain.IntegrationCode.NetSuiteVRA;
    details: NetSuiteVRADetails;
};

export type NetSuiteVRARequest = BaseRequest & NetSuiteVRARequestSpecifics;

export type NetSuiteBillPaymentRequestSpecifics = {
    integrationType: domain.IntegrationType.NetSuite;
    integrationCode: domain.IntegrationCode.NetSuiteBillPayment;
    details: NetSuiteBillPaymentDetails;
};

export type NetSuiteInvoiceRequestSpecifics = {
    integrationType: domain.IntegrationType.NetSuite;
    integrationCode: domain.IntegrationCode.NetSuiteInvoice;
    details: NetSuiteInvoiceDetails;
};

export type NetSuiteBillPaymentRequest = BaseRequest & NetSuiteBillPaymentRequestSpecifics;

export type NetSuiteInvoiceRequest = BaseRequest & NetSuiteInvoiceRequestSpecifics;

export type NetSuiteRARequestSpecifics = {
    integrationType: domain.IntegrationType.NetSuite;
    integrationCode: domain.IntegrationCode.NetSuiteRA;
    details: NetSuiteRADetails;
};

export enum NetSuiteAccountTypeGroup {
    CreditCard = 'CreditCard',
    Expense = 'Expense',
    ExpenseExtended = 'ExpenseExtended',
    Payable = 'Payable',
}

export type NetSuiteRARequest = BaseRequest & NetSuiteRARequestSpecifics;

export type NetSuiteRequestSpecifics =
    | NetSuiteBillRequestSpecifics
    | NetSuitePoRequestSpecifics
    | NetSuiteSalesOrderRequestSpecifics
    | NetSuiteExpenseReportRequestSpecifics
    | NetSuiteVRARequestSpecifics
    | NetSuiteBillPaymentRequestSpecifics
    | NetSuiteRARequestSpecifics
    | NetSuiteInvoiceRequestSpecifics;

export type NetSuiteRequest =
    | NetSuiteBillRequest
    | NetSuitePoRequest
    | NetSuiteSalesOrderRequest
    | NetSuiteExpenseReportRequest
    | NetSuiteVRARequest
    | NetSuiteBillPaymentRequest
    | NetSuiteRARequest
    | NetSuiteInvoiceRequest;
