import { Button, CheckboxEditor, Field, PasswordEditor, TextEditor } from '@approvalmax/ui';
import { Popup } from '@approvalmax/ui/src/components';
import { ui } from 'modules/common';
import { useCurrentUser, useUserProfile } from 'modules/utils';
import { memo, useState } from 'react';
import { api } from 'services/api';
import { storageService } from 'services/storage';
import TrustThisDeviceHint from 'shared/components/TrustThisDeviceHint/TrustThisDeviceHint';
import { getPath, Path } from 'urlBuilder';

import { messages } from './AuthErrorPopup.messages';
import {
    Description,
    Header,
    LoginButtonWrapper,
    PasswordRow,
    Root,
    StyledSsoPanel,
    Title,
    TrustThisDeviceContainer,
} from './AuthErrorPopup.styles';
import { AuthErrorPopupProps } from './AuthErrorPopup.types';

const AuthErrorPopup = memo<AuthErrorPopupProps>((props) => {
    const { open, onRequestClose } = props;

    const me = useCurrentUser();
    const profile = useUserProfile();

    const [trustThisDevice, setTrustThisDevice] = useState(false);
    const [password, setPassword] = useState('');
    const [stage, setStage] = useState<'initial' | '2faCode'>('initial');
    const [tfaStateId, setTfaStateId] = useState('');
    const [tfaCode, setTfaCode] = useState('');
    const [loading, setLoading] = useState(false);

    async function onLogin() {
        try {
            setLoading(true);

            const { RequiresTFA, TFAStateId } = await api.auth.login({
                email: me.userEmail,
                pwd: password,
            });

            if (!RequiresTFA) {
                storageService.setAuthenticated(true);

                onRequestClose();

                return;
            }

            setStage('2faCode');
            setTfaStateId(TFAStateId);
        } finally {
            setLoading(false);
        }
    }

    async function onConfirm2fa() {
        try {
            setLoading(true);

            await api.auth.finishTFALogin({
                stateId: tfaStateId,
                code: tfaCode,
                isDeviceTrusted: trustThisDevice,
            });

            storageService.setAuthenticated(true);

            onRequestClose();
        } finally {
            setLoading(false);
        }
    }

    return (
        <Popup open={open} closable={false} preventAutoClose zIndex={2000}>
            <Popup.Body spacing='32 48'>
                <Root>
                    <div>
                        <Header>
                            <ui.Avatar size={80} user={me} />

                            <Title>
                                {messages.title({
                                    br: <br />,
                                    name: me.displayName,
                                })}
                            </Title>
                        </Header>

                        <Description>
                            {profile.isPasswordSet ? messages.description : messages.descriptionWithoutPassword}
                        </Description>

                        {profile.isPasswordSet && (
                            <Field css='align-self: stretch; margin-top: 20px;' title={messages.passwordField}>
                                <PasswordRow>
                                    <PasswordEditor
                                        focusOnMount
                                        value={password}
                                        onChange={setPassword}
                                        onEnter={onLogin}
                                        disabled={stage === '2faCode'}
                                    />

                                    <Button
                                        execute={onLogin}
                                        preset='compact'
                                        disabled={!password || stage === '2faCode' || loading}
                                    >
                                        {messages.loginButton}
                                    </Button>
                                </PasswordRow>
                            </Field>
                        )}

                        {stage === '2faCode' && (
                            <Field css='align-self: stretch; margin-top: 20px;' title={messages.twoFaField}>
                                <PasswordRow>
                                    <TextEditor
                                        focusOnMount
                                        value={tfaCode}
                                        onChange={setTfaCode}
                                        onEnter={onConfirm2fa}
                                    />

                                    <Button execute={onConfirm2fa} preset='compact' disabled={!tfaCode || loading}>
                                        {messages.twoFaButton}
                                    </Button>
                                </PasswordRow>

                                <TrustThisDeviceContainer>
                                    <CheckboxEditor onChange={setTrustThisDevice} value={trustThisDevice}>
                                        {messages.trustThisDevice}
                                    </CheckboxEditor>

                                    <TrustThisDeviceHint />
                                </TrustThisDeviceContainer>
                            </Field>
                        )}

                        <LoginButtonWrapper>
                            <a href={getPath(Path.login)}>{messages.goToLogin}</a>
                        </LoginButtonWrapper>
                    </div>

                    <StyledSsoPanel
                        variant='signin'
                        shouldReturnToCurrentPage
                        vertical
                        width={232}
                        withWhiteButtons
                        // TODO: return to true after release 4.83 merge to dev
                        showPasskey={false}
                    />
                </Root>
            </Popup.Body>
        </Popup>
    );
});

export default AuthErrorPopup;
