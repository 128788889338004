import { Guid, Reference, UrlReference } from '@approvalmax/types';
import { errorHelpers, intl } from '@approvalmax/utils';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import { selectors, statics } from 'modules/common';
import { backend, domain, State } from 'modules/data';
import { ComponentClass, FC, PropsWithChildren } from 'react';
import { getPath, Path } from 'urlBuilder';

import { DateCell, ReferenceCell, StringCell, UrlReferenceCell } from '../../components/ReportTable/components';
import * as cellParsers from '../../data/cellParsers';
import * as cellPrinters from '../../data/cellPrinters';
import { Filter, FilterType } from '../../data/filters';
import * as dateRangeFilter from '../../data/filters/dateRangeFilter';
import * as numberRangeFilter from '../../data/filters/numberRangeFilter';
import * as referenceListFilter from '../../data/filters/referenceListFilter';
import * as referenceValueFilter from '../../data/filters/referenceValueFilter';
import { ReferenceValueFilter } from '../../data/filters/referenceValueFilter';
import * as stringFilter from '../../data/filters/stringFilter';
import { createCachedOptionsSelector } from '../../data/filters/utils';
import * as reportDomain from '../../types/domain';
import { NullableBoolean } from '../../types/domain';
import { ReportConfigColumn } from '../../types/ReportConfigColumn';
import { ColumnGroup } from '../columnGroups.types';
import { amaxPayPaymentStatusOptions } from '../standard/columnDefinitions';
import { matchedPOColumnMessages, retrospectivePOMessages } from '../standard/columnDefinitions.messages';
import { ParseCellValueFunction } from '../types';
import { messages, qboPOStatusColumnMessages, resolutionColumnMessages } from './columnLineItemDefinitions.messages';
import LineItemColumnKind from './lineItemColumnKind';

interface CellComponentProps {
    rowId: Guid;
    value: any;
    className?: string;
}

interface MapFeatureTolineItemReportCode {
    betaFeature?: domain.CompanyBetaFeature;
    reportCode?: domain.ReportCode;
    licenseFeature?: domain.CompanyLicenseFeature;
}

export interface DataProviderProps extends PropsWithChildren {}

export function getLineItemScopedColumns(
    columns: ReportConfigColumn[],
    reportCode: domain.ReportCode,
    company: selectors.types.ExpandedCompany
) {
    return columns.filter((c) => {
        const colDef = getLineItemColumnDefinitionById(c.id, reportCode);

        let isSave = true;

        if (colDef.scope.lineItemReportCode?.length) {
            isSave = colDef.scope.lineItemReportCode.includes(reportCode);
        }

        if (isSave && colDef.scope.excludeLineItemReportCode?.length) {
            isSave = !colDef.scope.excludeLineItemReportCode.includes(reportCode);
        }

        if (isSave && colDef.scope.requiredCompanyBetaOrLicenseFeatures && company) {
            isSave = colDef.scope.requiredCompanyBetaOrLicenseFeatures.every((featureMapping) => {
                const licenseFeatureMatches =
                    featureMapping.licenseFeature && company.licenseFeatures.includes(featureMapping.licenseFeature);

                const reportCodeMatches = featureMapping.reportCode ? featureMapping.reportCode === reportCode : true;

                return reportCodeMatches && licenseFeatureMatches;
            });
        }

        return isSave;
    });
}

export interface LineItemReportColumnDefinition {
    // 1. General info
    id: string;
    kind: LineItemColumnKind;
    name: string | ((reportCode: domain.ReportCode) => string);
    title: string | ((reportCode: domain.ReportCode) => string);
    group?: ColumnGroup | ((reportCode: domain.ReportCode) => ColumnGroup);
    requiresConnectedIntegration: boolean;
    requiresConnectedServices?: { airwallex?: boolean };
    isNotVisible?: boolean;
    visibleByDefault: boolean | ((reportCode: domain.ReportCode) => boolean);
    sortable: boolean;
    scope: {
        lineItemReportCode?: domain.ReportCode[];
        excludeLineItemReportCode?: domain.ReportCode[];
        requiredCompanyBetaOrLicenseFeatures?: MapFeatureTolineItemReportCode[];
    };
    fieldSystemPurpose?: domain.FieldSystemPurpose[];
    filterType: FilterType;
    // 2. Data rendering
    parseCellValue: ParseCellValueFunction;
    printCell: (value: any) => string;
    cellComponent: ComponentClass<CellComponentProps> | FC<CellComponentProps>;
    // 3. Filters
    dataProvider?: ComponentClass<DataProviderProps> | FC<DataProviderProps>;

    getOptions?(state: State, column: ReportConfigColumn | null, companyId: string): Reference[];

    parseFilter?(filterAnswer: any | null, state: State, companyId: string): Filter;

    getFilterTransfer?(filter: Filter): any;
}

export function getLineItemColumnDefinitionByKind(columnKind: LineItemColumnKind, reportCode: domain.ReportCode) {
    let colDef = columnLineItemDefinitions.find((x) => x.kind === columnKind);

    if (!colDef) {
        throw errorHelpers.notImplementedError();
    }

    return {
        ...colDef,
        name: typeof colDef.name === 'function' ? colDef.name(reportCode) : colDef.name,
        title: typeof colDef.title === 'function' ? colDef.title(reportCode) : colDef.title,
        group:
            typeof colDef.group === 'function' &&
            (!colDef.scope.lineItemReportCode || colDef.scope.lineItemReportCode.includes(reportCode))
                ? colDef.group(reportCode)
                : colDef.group,
        visibleByDefault:
            typeof colDef.visibleByDefault === 'function'
                ? colDef.visibleByDefault(reportCode)
                : colDef.visibleByDefault,
    };
}

export function getLineItemColumnDefinitionById(id: string, reportCode: domain.ReportCode) {
    let colDef = columnLineItemDefinitions.find((x) => x.id === id);

    if (!colDef) {
        throw errorHelpers.notImplementedError();
    }

    return {
        ...colDef,
        name: typeof colDef.name === 'function' ? colDef.name(reportCode) : colDef.name,
        title: typeof colDef.title === 'function' ? colDef.title(reportCode) : colDef.title,
        group:
            typeof colDef.group === 'function' &&
            (!colDef.scope.lineItemReportCode || colDef.scope.lineItemReportCode.includes(reportCode))
                ? colDef.group(reportCode)
                : colDef.group,
        visibleByDefault:
            typeof colDef.visibleByDefault === 'function'
                ? colDef.visibleByDefault(reportCode)
                : colDef.visibleByDefault,
    };
}

const payableReportCodes = [
    domain.ReportCode.AirwallexXeroBatchPaymentLine,
    domain.ReportCode.XeroAmaxPayBatchPaymentLine,
];

const amaxPayLinePaymentStatusOptions = [
    reportDomain.anyValueReference,
    {
        id: domain.AmaxPayPaymentStatus.None,
        text: messages.amaxPayLinePaymentStatusNone,
    },
    {
        id: domain.AmaxPayPaymentStatus.ReadyToPay,
        text: messages.amaxPayLinePaymentStatusReadyToPay,
    },
    {
        id: domain.AmaxPayPaymentStatus.Processing,
        text: messages.amaxPayLinePaymentStatusProcessing,
    },
    {
        id: domain.AmaxPayPaymentStatus.Paid,
        text: messages.amaxPayLinePaymentStatusPaid,
    },
    {
        id: domain.AmaxPayPaymentStatus.Failed,
        text: messages.amaxPayLinePaymentStatusFailed,
    },
    {
        id: domain.AmaxPayPaymentStatus.CancelledByUser,
        text: messages.amaxPayLinePaymentStatusCancelled,
    },
];

const columnLineItemDefinitions: LineItemReportColumnDefinition[] = [
    {
        id: LineItemColumnKind.DocumentTaxType,
        kind: LineItemColumnKind.DocumentTaxType,
        name: (reportCode: domain.ReportCode) => {
            if (
                [
                    domain.ReportCode.QBooksBillInvoiceLine,
                    domain.ReportCode.QBooksPurchaseOrderLine,
                    domain.ReportCode.QBooksExpenseLine,
                ].includes(reportCode)
            ) {
                return messages.qboTaxColumn;
            }

            return messages.taxColumn;
        },
        title: (reportCode: domain.ReportCode) => {
            if (
                [
                    domain.ReportCode.QBooksBillInvoiceLine,
                    domain.ReportCode.QBooksPurchaseOrderLine,
                    domain.ReportCode.QBooksExpenseLine,
                ].includes(reportCode)
            ) {
                return messages.qboTaxColumn;
            }

            return messages.taxColumn;
        },
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: false,
        scope: {
            excludeLineItemReportCode: [
                domain.ReportCode.AirwallexXeroBatchPaymentLine,
                domain.ReportCode.XeroAmaxPayBatchPaymentLine,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.TaxAmount,
        kind: LineItemColumnKind.TaxAmount,
        name: messages.taxAmount,
        title: messages.taxAmount,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: false,
        scope: {
            lineItemReportCode: [
                domain.ReportCode.XeroAPCreditNoteLine,
                domain.ReportCode.XeroARCreditNoteLine,
                domain.ReportCode.XeroBillInvoiceLine,
                domain.ReportCode.XeroPurchaseOrderLine,
                domain.ReportCode.XeroSalesInvoiceLine,
                domain.ReportCode.XeroQuoteLine,
                domain.ReportCode.QBooksSalesInvoiceLine,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.DocumentContact,
        kind: LineItemColumnKind.DocumentContact,
        name: (reportCode: domain.ReportCode) => {
            switch (reportCode) {
                case domain.ReportCode.QBooksBillInvoiceLine:
                case domain.ReportCode.QBooksPurchaseOrderLine:
                case domain.ReportCode.QBooksExpenseLine:
                    return messages.vendor;

                case domain.ReportCode.QBooksSalesInvoiceLine:
                    return messages.customer;

                default:
                    return messages.contact;
            }
        },
        title: messages.contactColumnTitle,
        group: (reportCode: domain.ReportCode) => {
            switch (reportCode) {
                case domain.ReportCode.AirwallexXeroBatchPaymentLine:
                case domain.ReportCode.XeroAmaxPayBatchPaymentLine:
                    return ColumnGroup.LineItemField;

                default:
                    return ColumnGroup.KeyData;
            }
        },
        requiresConnectedIntegration: true,
        visibleByDefault: (reportCode: domain.ReportCode) =>
            domain.ReportCode.AirwallexXeroBatchPaymentLine !== reportCode &&
            domain.ReportCode.XeroAmaxPayBatchPaymentLine !== reportCode,
        sortable: false,
        scope: {
            excludeLineItemReportCode: [domain.ReportCode.XeroManualJournalLine],
        },
        fieldSystemPurpose: [
            domain.FieldSystemPurpose.XeroSupplier,
            domain.FieldSystemPurpose.QBooksVendor,
            domain.FieldSystemPurpose.QBooksCustomer,
        ],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.PayeeCustomer,
        kind: LineItemColumnKind.PayeeCustomer,
        name: messages.payeeCustomer,
        title: messages.payeeCustomer,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        isNotVisible: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.QBooksExpenseLine],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksPayeeCustomer],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.PayeeEmployee,
        kind: LineItemColumnKind.PayeeEmployee,
        name: messages.payeeEmployee,
        title: messages.payeeEmployee,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        isNotVisible: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.QBooksExpenseLine],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksPayeeEmployee],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.PaymentAccount,
        kind: LineItemColumnKind.PaymentAccount,
        name: messages.paymentAccount,
        title: messages.paymentAccount,
        group: ColumnGroup.ExpenseDetails,
        requiresConnectedIntegration: true,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.QBooksExpenseLine],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksPaymentAccount],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.PaymentType,
        kind: LineItemColumnKind.PaymentType,
        name: messages.paymentType,
        title: messages.paymentType,
        group: ColumnGroup.ExpenseDetails,
        requiresConnectedIntegration: true,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.QBooksExpenseLine],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksPaymentType],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.PaymentMethod,
        kind: LineItemColumnKind.PaymentMethod,
        name: messages.paymentMethod,
        title: messages.paymentMethod,
        group: (reportCode: domain.ReportCode) => {
            switch (reportCode) {
                case domain.ReportCode.QBooksExpenseLine:
                    return ColumnGroup.ExpenseDetails;

                case domain.ReportCode.AirwallexXeroBatchPaymentLine:
                    return ColumnGroup.LineItemField;

                default:
                    throw errorHelpers.notSupportedError(reportCode);
            }
        },
        requiresConnectedIntegration: false,
        visibleByDefault: (reportCode: domain.ReportCode) =>
            domain.ReportCode.AirwallexXeroBatchPaymentLine !== reportCode,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.QBooksExpenseLine, domain.ReportCode.AirwallexXeroBatchPaymentLine],
        },
        fieldSystemPurpose: [
            domain.FieldSystemPurpose.QBooksPaymentMethod,
            domain.FieldSystemPurpose.AirwallexPaymentMethod,
        ],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.RequestCurrency,
        kind: LineItemColumnKind.RequestCurrency,
        name: messages.requestCurrency,
        title: messages.requestCurrency,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: true,
        sortable: false,
        scope: {
            excludeLineItemReportCode: [
                domain.ReportCode.AirwallexXeroBatchPaymentLine,
                domain.ReportCode.XeroAmaxPayBatchPaymentLine,
                domain.ReportCode.XeroManualJournalLine,
            ],
        },
        parseCellValue(value) {
            return statics.currency.getCurrencyShortText(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.ReferenceList,
        getOptions(): Reference[] {
            return statics.currency.currencyReferences;
        },
        parseFilter: referenceListFilter.idMappers.createParseFilter(),
        getFilterTransfer: referenceListFilter.idMappers.createGetFilterTransfer(),
    },
    {
        id: LineItemColumnKind.DocumentDate,
        kind: LineItemColumnKind.DocumentDate,
        name: (reportCode: domain.ReportCode) => {
            if (payableReportCodes.includes(reportCode)) {
                return messages.documentCreatedDate;
            }

            return messages.documentDate;
        },
        title: messages.documentDateColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {},
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.ReferenceList,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.ServiceDate,
        kind: LineItemColumnKind.ServiceDate,
        name: messages.serviceDate,
        title: messages.serviceDate,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.QBooksSalesInvoiceLine],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.ReferenceList,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.DocumentDepartment,
        kind: LineItemColumnKind.DocumentDepartment,
        name: messages.location,
        title: messages.location,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [
                domain.ReportCode.QBooksPurchaseOrderLine,
                domain.ReportCode.QBooksBillInvoiceLine,
                domain.ReportCode.QBooksExpenseLine,
                domain.ReportCode.QBooksSalesInvoiceLine,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksDepartment],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.RequestName,
        kind: LineItemColumnKind.RequestName,
        name: messages.name,
        title: messages.nameColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: false,
        scope: {},
        parseCellValue: (value, { requestId }, col, companyId) => {
            return {
                id: requestId,
                text: value,
                url: getPath(Path.request, requestId, companyId),
            } as UrlReference;
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: UrlReferenceCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.Resolution,
        kind: LineItemColumnKind.Resolution,
        name: (reportCode: domain.ReportCode) => {
            if (payableReportCodes.includes(reportCode)) {
                return messages.resolutionBatch;
            }

            return messages.resolution;
        },
        title: messages.resolutionColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {},
        parseCellValue(value: domain.RequestStatusV2, rowId, state, companyId): Reference {
            return this.getOptions!(state, null, companyId).find((o) => o.id === value) || { id: '', text: '' };
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        getOptions: createCachedOptionsSelector(() => {
            return [
                {
                    id: domain.RequestStatusV2.OnReview,
                    text: resolutionColumnMessages.onReview,
                },
                {
                    id: domain.RequestStatusV2.OnApproval,
                    text: resolutionColumnMessages.onApproval,
                },
                {
                    id: domain.RequestStatusV2.Approved,
                    text: resolutionColumnMessages.approved,
                },
                {
                    id: domain.RequestStatusV2.Cancelled,
                    text: resolutionColumnMessages.cancelled,
                },
                {
                    id: domain.RequestStatusV2.Rejected,
                    text: resolutionColumnMessages.rejected,
                },
                {
                    id: domain.RequestStatusV2.OnHold,
                    text: resolutionColumnMessages.onHold,
                },
            ];
        }),
        parseFilter: referenceListFilter.enumMappers.createParseFilter(backend.RequestsRequestResolution),
        getFilterTransfer: referenceListFilter.enumMappers.createGetFilterTransfer(backend.RequestsRequestResolution),
    },
    {
        id: LineItemColumnKind.IsBilled,
        kind: LineItemColumnKind.IsBilled,
        name: messages.isBilled,
        title: messages.isBilledColumnTitle,
        group: ColumnGroup.PoDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroPurchaseOrderLine],
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            return (this.parseFilter!(value, state, companyId) as ReferenceValueFilter).value;
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceValue,
        getOptions: referenceValueFilter.getNullableBooleanOptions,
        parseFilter: referenceValueFilter.parseNullableBooleanFilter,
        getFilterTransfer: referenceValueFilter.getNullableBooleanFilterTransfer,
    },

    {
        id: LineItemColumnKind.DocumentDeliveryDate,
        kind: LineItemColumnKind.DocumentDeliveryDate,
        name: messages.deliveryDate,
        title: messages.deliveryDateColumnTitle,
        group: ColumnGroup.PoDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroPurchaseOrderLine],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.DueDate,
        kind: LineItemColumnKind.DueDate,
        name: messages.dueDate,
        title: messages.dueDateColumnTitle,
        group: (reportCode: domain.ReportCode) => {
            switch (reportCode) {
                case domain.ReportCode.XeroBillInvoiceLine:
                    return ColumnGroup.BillDetails;

                case domain.ReportCode.XeroSalesInvoiceLine:
                    return ColumnGroup.SalesInvoiceDetails;

                case domain.ReportCode.QBooksBillInvoiceLine:
                    return ColumnGroup.BillDetails;

                case domain.ReportCode.AirwallexXeroBatchPaymentLine:
                case domain.ReportCode.XeroAmaxPayBatchPaymentLine:
                    return ColumnGroup.LineItemField;

                default:
                    throw errorHelpers.notSupportedError(reportCode);
            }
        },
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [
                domain.ReportCode.XeroBillInvoiceLine,
                domain.ReportCode.XeroSalesInvoiceLine,
                domain.ReportCode.QBooksBillInvoiceLine,
                domain.ReportCode.AirwallexXeroBatchPaymentLine,
                domain.ReportCode.XeroAmaxPayBatchPaymentLine,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.IsPaid,
        kind: LineItemColumnKind.IsPaid,
        name: messages.isPaid,
        title: messages.isPaidColumnTitle,
        group: ColumnGroup.BillDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroBillInvoiceLine, domain.ReportCode.QBooksBillInvoiceLine],
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            return (this.parseFilter!(value, state, companyId) as ReferenceValueFilter).value;
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceValue,
        getOptions: referenceValueFilter.getNullableBooleanOptions,
        parseFilter: referenceValueFilter.parseNullableBooleanFilter,
        getFilterTransfer: referenceValueFilter.getNullableBooleanFilterTransfer,
    },
    {
        id: LineItemColumnKind.DocumentPaymentDate,
        kind: LineItemColumnKind.DocumentPaymentDate,
        name: messages.plannedPaymentDate,
        title: messages.plannedPaymentDateColumnTitle,
        group: ColumnGroup.BillDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroBillInvoiceLine],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.Item,
        kind: LineItemColumnKind.Item,
        name: (reportCode: domain.ReportCode) => {
            if (
                [
                    domain.ReportCode.QBooksBillInvoiceLine,
                    domain.ReportCode.QBooksPurchaseOrderLine,
                    domain.ReportCode.QBooksExpenseLine,
                    domain.ReportCode.QBooksSalesInvoiceLine,
                ].includes(reportCode)
            ) {
                return messages.productService;
            }

            return messages.item;
        },
        title: (reportCode: domain.ReportCode) => {
            if (
                [
                    domain.ReportCode.QBooksBillInvoiceLine,
                    domain.ReportCode.QBooksPurchaseOrderLine,
                    domain.ReportCode.QBooksExpenseLine,
                    domain.ReportCode.QBooksSalesInvoiceLine,
                ].includes(reportCode)
            ) {
                return messages.productService;
            }

            return messages.item;
        },
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: true,
        visibleByDefault: true,
        sortable: false,
        scope: {
            lineItemReportCode: [
                domain.ReportCode.XeroAPCreditNoteLine,
                domain.ReportCode.XeroARCreditNoteLine,
                domain.ReportCode.XeroBillInvoiceLine,
                domain.ReportCode.XeroPurchaseOrderLine,
                domain.ReportCode.XeroSalesInvoiceLine,
                domain.ReportCode.XeroQuoteLine,
                domain.ReportCode.QBooksBillInvoiceLine,
                domain.ReportCode.QBooksPurchaseOrderLine,
                domain.ReportCode.QBooksExpenseLine,
                domain.ReportCode.QBooksSalesInvoiceLine,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroItem, domain.FieldSystemPurpose.QBooksPoItem],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.Description,
        kind: LineItemColumnKind.Description,
        name: (reportCode: domain.ReportCode) =>
            [
                domain.ReportCode.QBooksSalesInvoiceLine,
                domain.ReportCode.XeroAPCreditNoteLine,
                domain.ReportCode.XeroARCreditNoteLine,
                domain.ReportCode.XeroBillInvoiceLine,
                domain.ReportCode.XeroPurchaseOrderLine,
                domain.ReportCode.XeroSalesInvoiceLine,
                domain.ReportCode.XeroQuoteLine,
            ].includes(reportCode)
                ? messages.description
                : messages.item,
        title: messages.itemDescriptionTitle,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: false,
        scope: {
            lineItemReportCode: [
                domain.ReportCode.XeroAPCreditNoteLine,
                domain.ReportCode.XeroARCreditNoteLine,
                domain.ReportCode.XeroBillInvoiceLine,
                domain.ReportCode.XeroPurchaseOrderLine,
                domain.ReportCode.XeroSalesInvoiceLine,
                domain.ReportCode.XeroQuoteLine,
                domain.ReportCode.XeroManualJournalLine,
                domain.ReportCode.QBooksBillInvoiceLine,
                domain.ReportCode.QBooksPurchaseOrderLine,
                domain.ReportCode.QBooksExpenseLine,
                domain.ReportCode.QBooksSalesInvoiceLine,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },

    {
        id: LineItemColumnKind.Quantity,
        kind: LineItemColumnKind.Quantity,
        name: messages.quantity,
        title: messages.quantity,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: false,
        scope: {
            excludeLineItemReportCode: [
                domain.ReportCode.AirwallexXeroBatchPaymentLine,
                domain.ReportCode.XeroAmaxPayBatchPaymentLine,
                domain.ReportCode.XeroManualJournalLine,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.UnitPrice,
        kind: LineItemColumnKind.UnitPrice,
        name: (reportCode: domain.ReportCode) => {
            if (
                [
                    domain.ReportCode.QBooksBillInvoiceLine,
                    domain.ReportCode.QBooksPurchaseOrderLine,
                    domain.ReportCode.QBooksExpenseLine,
                    domain.ReportCode.QBooksSalesInvoiceLine,
                ].includes(reportCode)
            ) {
                return messages.rate;
            }

            return messages.unitPrice;
        },
        title: (reportCode: domain.ReportCode) => {
            if (
                [
                    domain.ReportCode.QBooksBillInvoiceLine,
                    domain.ReportCode.QBooksExpenseLine,
                    domain.ReportCode.QBooksSalesInvoiceLine,
                ].includes(reportCode)
            ) {
                return messages.rate;
            }

            return messages.unitPrice;
        },
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: false,
        scope: {
            excludeLineItemReportCode: [
                domain.ReportCode.AirwallexXeroBatchPaymentLine,
                domain.ReportCode.XeroAmaxPayBatchPaymentLine,
                domain.ReportCode.XeroManualJournalLine,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.Discount,
        kind: LineItemColumnKind.Discount,
        name: messages.discount,
        title: messages.discount,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: false,
        parseCellValue: cellParsers.parsePercentage,
        scope: {
            lineItemReportCode: [
                domain.ReportCode.XeroPurchaseOrderLine,
                domain.ReportCode.XeroSalesInvoiceLine,
                domain.ReportCode.QBooksSalesInvoiceLine,
            ],
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.DocumentNumber,
        kind: LineItemColumnKind.DocumentNumber,
        name: messages.poNumber,
        title: messages.poNumber,
        group: ColumnGroup.PoDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: false,
        parseCellValue: cellParsers.parseNoop,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroPurchaseOrderLine],
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.Class,
        kind: LineItemColumnKind.Class,
        name: messages.class,
        title: messages.class,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: false,
        scope: {
            lineItemReportCode: [
                domain.ReportCode.QBooksPurchaseOrderLine,
                domain.ReportCode.QBooksBillInvoiceLine,
                domain.ReportCode.QBooksExpenseLine,
                domain.ReportCode.QBooksSalesInvoiceLine,
            ],
        },

        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksClass],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.Account,
        kind: LineItemColumnKind.Account,
        name: (reportCode: domain.ReportCode) => {
            if (
                [
                    domain.ReportCode.QBooksBillInvoiceLine,
                    domain.ReportCode.QBooksPurchaseOrderLine,
                    domain.ReportCode.QBooksExpenseLine,
                ].includes(reportCode)
            ) {
                return messages.category;
            }

            return messages.account;
        },
        title: (reportCode: domain.ReportCode) => {
            if (
                [
                    domain.ReportCode.QBooksBillInvoiceLine,
                    domain.ReportCode.QBooksPurchaseOrderLine,
                    domain.ReportCode.QBooksExpenseLine,
                ].includes(reportCode)
            ) {
                return messages.category;
            }

            return messages.account;
        },
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: true,
        visibleByDefault: true,
        sortable: false,
        scope: {
            excludeLineItemReportCode: [
                domain.ReportCode.AirwallexXeroBatchPaymentLine,
                domain.ReportCode.XeroAmaxPayBatchPaymentLine,
                domain.ReportCode.QBooksSalesInvoiceLine,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroAccount, domain.FieldSystemPurpose.QBooksAccount],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.TaxRate,
        kind: LineItemColumnKind.TaxRate,
        name: messages.taxRate,
        title: messages.taxRate,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: false,
        scope: {
            lineItemReportCode: [
                domain.ReportCode.XeroAPCreditNoteLine,
                domain.ReportCode.XeroARCreditNoteLine,
                domain.ReportCode.XeroBillInvoiceLine,
                domain.ReportCode.XeroPurchaseOrderLine,
                domain.ReportCode.XeroSalesInvoiceLine,
                domain.ReportCode.XeroQuoteLine,
                domain.ReportCode.XeroManualJournalLine,
                domain.ReportCode.QBooksSalesInvoiceLine,
            ],
        },
        parseCellValue: cellParsers.parsePercentage,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.Tracking1,
        kind: LineItemColumnKind.Tracking1,
        name: messages.lineItemTrackings1,
        title: messages.lineItemTrackings1,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: false,
        scope: {
            excludeLineItemReportCode: [
                domain.ReportCode.AirwallexXeroBatchPaymentLine,
                domain.ReportCode.XeroAmaxPayBatchPaymentLine,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroTracking],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.Tracking2,
        kind: LineItemColumnKind.Tracking2,
        name: messages.lineItemTrackings2,
        title: messages.lineItemTrackings2,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: false,
        scope: {
            excludeLineItemReportCode: [
                domain.ReportCode.AirwallexXeroBatchPaymentLine,
                domain.ReportCode.XeroAmaxPayBatchPaymentLine,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroTracking],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.Amount,
        kind: LineItemColumnKind.Amount,
        name: (reportCode: domain.ReportCode) => {
            if (payableReportCodes.includes(reportCode)) {
                return messages.paymentAmount;
            }

            if (reportCode === domain.ReportCode.XeroManualJournalLine) {
                return messages.totalAmount;
            }

            return messages.amount;
        },
        title: (reportCode: domain.ReportCode) => {
            if (payableReportCodes.includes(reportCode)) {
                return messages.paymentAmount;
            }

            if (reportCode === domain.ReportCode.XeroManualJournalLine) {
                return messages.totalAmount;
            }

            return messages.amount;
        },
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {},
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.PaymentDetails,
        kind: LineItemColumnKind.PaymentDetails,
        name: messages.paymentDetails,
        title: messages.paymentDetails,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroAmaxPayBatchPaymentLine],
        },
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.AmaxPayPaymentStatus,
        kind: LineItemColumnKind.AmaxPayPaymentStatus,
        name: messages.amaxPayLinePaymentStatus,
        title: messages.amaxPayLinePaymentStatus,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroAmaxPayBatchPaymentLine],
        },
        parseCellValue: (value) => {
            if (typeof value === 'string') {
                const paymentStatus = amaxPayLinePaymentStatusOptions.find((option) => option.id === value);

                return paymentStatus?.text ?? value;
            }

            return value;
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
        getOptions: createCachedOptionsSelector(() => {
            return amaxPayLinePaymentStatusOptions;
        }),
    },
    {
        id: LineItemColumnKind.Customer,
        kind: LineItemColumnKind.Customer,
        name: messages.customers,
        title: messages.customers,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [
                domain.ReportCode.QBooksBillInvoiceLine,
                domain.ReportCode.QBooksPurchaseOrderLine,
                domain.ReportCode.QBooksExpenseLine,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksCustomer],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.IsMatched,
        kind: LineItemColumnKind.IsMatched,
        name: messages.lineItemIsMatched,
        title: messages.matched,
        group: ColumnGroup.Matching,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.QBooksPurchaseOrderLine, domain.ReportCode.QBooksBillInvoiceLine],
            requiredCompanyBetaOrLicenseFeatures: [
                {
                    licenseFeature: domain.CompanyLicenseFeature.QBOMatching,
                },
            ],
        },
        parseCellValue(value, meta, col, companyId) {
            const reference = cellParsers.parseReference(value);

            return {
                ...reference,
                url: getPath(Path.request, reference.id, companyId),
            };
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: UrlReferenceCell,
        filterType: FilterType.ReferenceList,
        getOptions: referenceValueFilter.getNullableBooleanOptions,
        parseFilter: referenceValueFilter.parseNullableBooleanFilter,
        getFilterTransfer: referenceValueFilter.getNullableBooleanFilterTransfer,
    },

    {
        id: LineItemColumnKind.QBooksPurchaseOrderStatus,
        kind: LineItemColumnKind.QBooksPurchaseOrderStatus,
        name: messages.poStatus,
        title: messages.poStatus,
        group: ColumnGroup.PoDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.QBooksPurchaseOrderLine],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.String,
        getOptions: createCachedOptionsSelector(() => {
            return [
                reportDomain.anyValueReference,
                {
                    id: domain.QBooksPurchaseOrderStatus.Open,
                    text: qboPOStatusColumnMessages.qboPurchaseOrderStatusOptionOpen,
                },
                {
                    id: domain.QBooksPurchaseOrderStatus.Closed,
                    text: qboPOStatusColumnMessages.qboPurchaseOrderStatusOptionClosed,
                },
            ];
        }),
        parseFilter(filterAnswer, state, companyId): ReferenceValueFilter {
            const valueIncoming = filterAnswer ? filterAnswer.Id : null;

            let value: Reference;

            const options: Reference[] = this.getOptions!(state, null, companyId);

            switch (valueIncoming) {
                case domain.QBooksPurchaseOrderStatusNumeric.Open:
                    value = options.find((option) => option.id === domain.QBooksPurchaseOrderStatus.Open)!;
                    break;

                case domain.QBooksPurchaseOrderStatusNumeric.Closed:
                    value = options.find((option) => option.id === domain.QBooksPurchaseOrderStatus.Closed)!;
                    break;

                default:
                    value = options.find((option) => option.id === reportDomain.anyValueReferenceId)!;
                    break;
            }

            return {
                type: FilterType.ReferenceValue,
                value,
            };
        },
        getFilterTransfer: (filter: ReferenceValueFilter): any => {
            switch (filter.value.id) {
                case reportDomain.anyValueReferenceId:
                    return null;

                case domain.QBooksPurchaseOrderStatus.Open:
                    return {
                        id: domain.QBooksPurchaseOrderStatusNumeric.Open,
                        text: domain.QBooksPurchaseOrderStatus.Open,
                    };

                case domain.QBooksPurchaseOrderStatus.Closed:
                    return {
                        id: domain.QBooksPurchaseOrderStatusNumeric.Closed,
                        text: domain.QBooksPurchaseOrderStatus.Closed,
                    };

                default:
                    throw errorHelpers.invalidOperationError();
            }
        },
    },
    {
        id: LineItemColumnKind.BatchPaymentStatus,
        kind: LineItemColumnKind.BatchPaymentStatus,
        name: messages.batchPaymentStatus,
        title: messages.batchPaymentStatus,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.AirwallexXeroBatchPaymentLine],
        },
        parseCellValue: (value: string | null) => {
            return capitalize(lowerCase(value || '-'));
        },
        printCell: cellPrinters.printCellNoop,
        fieldSystemPurpose: [domain.FieldSystemPurpose.AirwallexBatchPaymentStatus],
        cellComponent: StringCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.AmaxPayBatchPaymentStatus,
        kind: LineItemColumnKind.AmaxPayBatchPaymentStatus,
        name: messages.batchPaymentStatus,
        title: messages.batchPaymentStatus,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroAmaxPayBatchPaymentLine],
        },
        parseCellValue: (value) => {
            if (typeof value === 'string') {
                const paymentStatus = amaxPayPaymentStatusOptions.find((option) => option.id === value);

                return paymentStatus?.text ?? value;
            }

            return value;
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
        getOptions: createCachedOptionsSelector(() => {
            return amaxPayPaymentStatusOptions;
        }),
    },
    {
        id: LineItemColumnKind.PaymentStatus,
        kind: LineItemColumnKind.PaymentStatus,
        name: messages.paymentStatus,
        title: messages.paymentStatus,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.AirwallexXeroBatchPaymentLine],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        fieldSystemPurpose: [domain.FieldSystemPurpose.AirwallexPaymentItemStatus],
        cellComponent: StringCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.AirwallexBeneficiary,
        kind: LineItemColumnKind.AirwallexBeneficiary,
        name: messages.beneficiary,
        title: messages.beneficiary,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        requiresConnectedServices: { airwallex: true },
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.AirwallexXeroBatchPaymentLine],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.AirwallexBeneficiary],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.AirwallexBillCurrency,
        kind: LineItemColumnKind.AirwallexBillCurrency,
        name: messages.billCurrency,
        title: messages.billCurrency,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.AirwallexXeroBatchPaymentLine],
        },
        parseCellValue(value) {
            return statics.currency.getCurrencyShortText(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.ReferenceList,
        getOptions(): Reference[] {
            return statics.currency.currencyReferences;
        },
        parseFilter: referenceListFilter.idMappers.createParseFilter(),
        getFilterTransfer: referenceListFilter.idMappers.createGetFilterTransfer(),
    },
    {
        id: LineItemColumnKind.AmaxPayBillCurrency,
        kind: LineItemColumnKind.AmaxPayBillCurrency,
        name: messages.amaxPayBatchPaymentBillCurrency,
        title: messages.amaxPayBatchPaymentBillCurrency,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroAmaxPayBatchPaymentLine],
        },
        parseCellValue(value) {
            return statics.currency.getCurrencyShortText(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.ReferenceList,
        getOptions(): Reference[] {
            return statics.currency.currencyReferences;
        },
        parseFilter: referenceListFilter.idMappers.createParseFilter(),
        getFilterTransfer: referenceListFilter.idMappers.createGetFilterTransfer(),
    },
    {
        id: LineItemColumnKind.AirwallexSourceCurrency,
        kind: LineItemColumnKind.AirwallexSourceCurrency,
        name: messages.sourceCurrency,
        title: messages.sourceCurrency,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        requiresConnectedServices: { airwallex: true },
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.AirwallexXeroBatchPaymentLine],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.AirwallexCurrency],
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.ReferenceList,
        parseFilter(filterAnswer, _state, _companyId): referenceListFilter.ReferenceListFilter {
            const values = (filterAnswer || []).map((filterValue: string) => ({
                id: filterValue,
                text: filterValue,
            }));

            return {
                type: FilterType.ReferenceList,
                values,
            };
        },
        getFilterTransfer: referenceListFilter.idMappers.createGetFilterTransfer(),
    },

    {
        id: LineItemColumnKind.AirwallexPaymentPurpose,
        kind: LineItemColumnKind.AirwallexPaymentPurpose,
        name: messages.purpose,
        title: messages.purpose,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.AirwallexXeroBatchPaymentLine],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.AirwallexPaymentPurpose],
        parseCellValue(value): Reference {
            if (value === 'wages_salary') {
                return {
                    id: value,
                    text: messages.wagesOrSalary,
                };
            }

            return value ? { id: value, text: capitalize(lowerCase(value)) } : { id: '', text: '' };
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.AirwallexPaymentDate,
        kind: LineItemColumnKind.AirwallexPaymentDate,
        name: messages.paymentDate,
        title: messages.paymentDate,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.AirwallexXeroBatchPaymentLine],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.AmaxPayPaymentDate,
        kind: LineItemColumnKind.AmaxPayPaymentDate,
        name: messages.paymentDate,
        title: messages.paymentDate,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroAmaxPayBatchPaymentLine],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.BankAccount,
        kind: LineItemColumnKind.BankAccount,
        name: messages.xeroBankAccount,
        title: messages.xeroBankAccount,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.AirwallexXeroBatchPaymentLine],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroBankAccount],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.AmaxPayBankAccountName,
        kind: LineItemColumnKind.AmaxPayBankAccountName,
        name: messages.bankAccount,
        title: messages.bankAccount,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroAmaxPayBatchPaymentLine],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },

    {
        id: LineItemColumnKind.AirwallexFeeAmount,
        kind: LineItemColumnKind.AirwallexFeeAmount,
        name: messages.feeAmount,
        title: messages.feeAmount,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.AirwallexXeroBatchPaymentLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.AirwallexSourceAmount,
        kind: LineItemColumnKind.AirwallexSourceAmount,
        name: messages.sourceAmount,
        title: messages.sourceAmount,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.AirwallexXeroBatchPaymentLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.Bill,
        kind: LineItemColumnKind.Bill,
        name: messages.bill,
        title: messages.bill,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: true,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [
                domain.ReportCode.AirwallexXeroBatchPaymentLine,
                domain.ReportCode.XeroAmaxPayBatchPaymentLine,
            ],
        },
        parseCellValue: (value, _, col, companyId) => {
            const { Id: billId, Name: billName } = value || {};

            return {
                id: billId,
                text: billName,
                url: getPath(Path.request, billId, companyId),
            } as UrlReference;
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: UrlReferenceCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.BillAmount,
        kind: LineItemColumnKind.BillAmount,
        name: messages.billAmount,
        title: messages.billAmount,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [
                domain.ReportCode.AirwallexXeroBatchPaymentLine,
                domain.ReportCode.XeroAmaxPayBatchPaymentLine,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.PaymentFee,
        kind: LineItemColumnKind.PaymentFee,
        name: messages.feeType,
        title: messages.feeType,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: false,
        scope: {
            lineItemReportCode: [domain.ReportCode.AirwallexXeroBatchPaymentLine],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.PaymentReference,
        kind: LineItemColumnKind.PaymentReference,
        name: messages.paymentReference,
        title: messages.paymentReference,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: false,
        scope: {
            lineItemReportCode: [
                domain.ReportCode.AirwallexXeroBatchPaymentLine,
                domain.ReportCode.XeroAmaxPayBatchPaymentLine,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.isAccepted,
        kind: LineItemColumnKind.isAccepted,
        name: messages.isAcceptedColumnName,
        title: messages.isAcceptedColumnTitle,
        group: ColumnGroup.QuoteDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroQuoteLine],
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            return (this.parseFilter!(value, state, companyId) as ReferenceValueFilter).value;
        },
        getOptions: createCachedOptionsSelector(() => [
            reportDomain.anyValueReference,
            {
                id: NullableBoolean.Yes,
                text: messages.isAcceptedTrue,
            },
            {
                id: NullableBoolean.No,
                text: messages.isAcceptedFalse,
            },
        ]),
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceValue,
        parseFilter: referenceValueFilter.parseNullableBooleanFilter,
        getFilterTransfer: referenceValueFilter.getNullableBooleanFilterTransfer,
    },
    {
        id: LineItemColumnKind.SumOfAllocations,
        kind: LineItemColumnKind.SumOfAllocations,
        name: messages.sumOfAllocationsName,
        title: messages.sumOfAllocationsTitle,
        group: ColumnGroup.Matching,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroPurchaseOrderLine, domain.ReportCode.XeroBillInvoiceLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.NetRemainingBalance,
        kind: LineItemColumnKind.NetRemainingBalance,
        name: messages.netRemainingBalanceName,
        title: messages.netRemainingBalanceTitle,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroPurchaseOrderLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.GrossRemainingBalance,
        kind: LineItemColumnKind.GrossRemainingBalance,
        name: messages.grossRemainingBalanceName,
        title: messages.grossRemainingBalanceTitle,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroPurchaseOrderLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.NetAmount,
        kind: LineItemColumnKind.NetAmount,
        name: messages.netAmountName,
        title: messages.netAmountTitle,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroBillInvoiceLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.GrossAmount,
        kind: LineItemColumnKind.GrossAmount,
        name: messages.grossAmountName,
        title: messages.grossAmountTitle,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroBillInvoiceLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.PurchaseOrderBalance,
        kind: LineItemColumnKind.PurchaseOrderBalance,
        name: messages.purchaseOrderBalanceColumnName,
        title: messages.purchaseOrderBalanceColumnTitle,
        group: ColumnGroup.Matching,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroPurchaseOrderLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    },
    {
        id: LineItemColumnKind.MatchedBills,
        kind: LineItemColumnKind.MatchedBills,
        name: messages.matchedBillsColumnName,
        title: messages.matchedBillsColumnTitle,
        group: ColumnGroup.Matching,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroPurchaseOrderLine],
        },
        parseCellValue(value, meta, col, companyId): UrlReference {
            const reference = cellParsers.parseReference(value);

            return {
                ...reference,
                url: getPath(Path.request, reference.id, companyId),
            };
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: UrlReferenceCell,
        filterType: FilterType.ReferenceValue,
        getOptions() {
            return [
                reportDomain.anyValueReference,
                {
                    id: reportDomain.RequestMatchingStatus.Matched,
                    text: matchedPOColumnMessages.matched,
                },
                {
                    id: reportDomain.RequestMatchingStatus.NotMatched,
                    text: matchedPOColumnMessages.notMatched,
                },
            ];
        },
        parseFilter: referenceValueFilter.enumBackend.createParseFilter({
            null: reportDomain.anyValueReferenceId,
            [backend.RequestMatchingStatus.AnyValue]: reportDomain.anyValueReferenceId,
            [backend.RequestMatchingStatus.Matched]: reportDomain.RequestMatchingStatus.Matched,
            [backend.RequestMatchingStatus.NotMatched]: reportDomain.RequestMatchingStatus.NotMatched,
        }),
        getFilterTransfer: referenceValueFilter.enumBackend.createGetFilterTransfer({
            [reportDomain.anyValueReferenceId]: backend.RequestMatchingStatus.AnyValue,
            [reportDomain.RequestMatchingStatus.Matched]: backend.RequestMatchingStatus.Matched,
            [reportDomain.RequestMatchingStatus.NotMatched]: backend.RequestMatchingStatus.NotMatched,
        }),
    },
    {
        id: LineItemColumnKind.GrnStatus,
        kind: LineItemColumnKind.GrnStatus,
        name: messages.grnColumnName,
        title: messages.grnColumnTitle,
        group: ColumnGroup.GrnStatus,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroPurchaseOrderLine],
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            return (this.parseFilter!(value, state, companyId) as ReferenceValueFilter).value;
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceValue,
        getOptions: createCachedOptionsSelector(() => [
            reportDomain.anyValueReference,
            {
                id: domain.GoodsReceivedNotesStatus.NotReceived,
                text: messages.grnNotReceived,
            },
            {
                id: domain.GoodsReceivedNotesStatus.PartiallyReceived,
                text: messages.grnPartiallyReceived,
            },
            {
                id: domain.GoodsReceivedNotesStatus.FullyReceived,
                text: messages.grnFullyReceived,
            },
        ]),
        parseFilter(filterAnswer): ReferenceValueFilter {
            let value: Reference;

            switch (filterAnswer) {
                case domain.GoodsReceivedNotesStatus.NotReceived:
                    value = {
                        id: domain.GoodsReceivedNotesStatus.NotReceived,
                        text: messages.grnNotReceived,
                    };
                    break;

                case domain.GoodsReceivedNotesStatus.PartiallyReceived:
                    value = {
                        id: domain.GoodsReceivedNotesStatus.PartiallyReceived,
                        text: messages.grnPartiallyReceived,
                    };
                    break;

                case domain.GoodsReceivedNotesStatus.FullyReceived:
                    value = {
                        id: domain.GoodsReceivedNotesStatus.FullyReceived,
                        text: messages.grnFullyReceived,
                    };
                    break;

                default:
                    value = reportDomain.anyValueReference;
                    break;
            }

            return {
                type: FilterType.ReferenceValue,
                value,
            };
        },
        getFilterTransfer(filter: ReferenceValueFilter): any {
            switch (filter.value.id) {
                case reportDomain.anyValueReferenceId:
                    return null;

                case domain.GoodsReceivedNotesStatus.NotReceived:
                case domain.GoodsReceivedNotesStatus.PartiallyReceived:
                case domain.GoodsReceivedNotesStatus.FullyReceived:
                    return filter.value.id;

                default:
                    throw errorHelpers.invalidOperationError();
            }
        },
    },
    {
        id: LineItemColumnKind.RetrospectivePurchaseOrder,
        kind: LineItemColumnKind.RetrospectivePurchaseOrder,
        name: messages.retrospectivePurchaseOrderColumnName,
        title: messages.retrospectivePurchaseOrderColumnTitle,
        group: ColumnGroup.PoDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroPurchaseOrderLine],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.ReferenceValue,
        getOptions() {
            return [
                reportDomain.anyValueReference,
                {
                    id: reportDomain.RetrospectivePurchaseOrder.IsRetrospective,
                    text: retrospectivePOMessages.retrospective,
                },
                {
                    id: reportDomain.RetrospectivePurchaseOrder.IsNotRetrospective,
                    text: retrospectivePOMessages.notRetrospective,
                },
            ];
        },
        parseFilter: referenceValueFilter.enumBackend.createParseFilter({
            null: reportDomain.anyValueReferenceId,
            [backend.RetrospectivePurchaseOrder.AnyValue]: reportDomain.anyValueReferenceId,
            [backend.RetrospectivePurchaseOrder.IsRetrospective]:
                reportDomain.RetrospectivePurchaseOrder.IsRetrospective,
            [backend.RetrospectivePurchaseOrder.IsNotRetrospective]:
                reportDomain.RetrospectivePurchaseOrder.IsNotRetrospective,
        }),
        getFilterTransfer: referenceValueFilter.enumBackend.createGetFilterTransfer({
            [reportDomain.anyValueReferenceId]: backend.RetrospectivePurchaseOrder.AnyValue,
            [reportDomain.RetrospectivePurchaseOrder.IsRetrospective]:
                backend.RetrospectivePurchaseOrder.IsRetrospective,
            [reportDomain.RetrospectivePurchaseOrder.IsNotRetrospective]:
                backend.RetrospectivePurchaseOrder.IsNotRetrospective,
        }),
    },
    {
        id: LineItemColumnKind.MatchedPurchaseOrders,
        kind: LineItemColumnKind.MatchedPurchaseOrders,
        name: messages.matchedPurchaseOrdersColumnName,
        title: messages.matchedPurchaseOrdersColumnTitle,
        group: ColumnGroup.Matching,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroBillInvoiceLine],
        },
        parseCellValue(value, meta, col, companyId): UrlReference {
            const reference = cellParsers.parseReference(value);

            return {
                ...reference,
                url: getPath(Path.request, reference.id, companyId),
            };
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: UrlReferenceCell,
        filterType: FilterType.ReferenceValue,

        getOptions() {
            return [
                reportDomain.anyValueReference,
                {
                    id: reportDomain.RequestMatchingStatus.Matched,
                    text: matchedPOColumnMessages.matched,
                },
                {
                    id: reportDomain.RequestMatchingStatus.NotMatched,
                    text: matchedPOColumnMessages.notMatched,
                },
            ];
        },
        parseFilter: referenceValueFilter.enumBackend.createParseFilter({
            null: reportDomain.anyValueReferenceId,
            [backend.RequestMatchingStatus.AnyValue]: reportDomain.anyValueReferenceId,
            [backend.RequestMatchingStatus.Matched]: reportDomain.RequestMatchingStatus.Matched,
            [backend.RequestMatchingStatus.NotMatched]: reportDomain.RequestMatchingStatus.NotMatched,
        }),
        getFilterTransfer: referenceValueFilter.enumBackend.createGetFilterTransfer({
            [reportDomain.anyValueReferenceId]: backend.RequestMatchingStatus.AnyValue,
            [reportDomain.RequestMatchingStatus.Matched]: backend.RequestMatchingStatus.Matched,
            [reportDomain.RequestMatchingStatus.NotMatched]: backend.RequestMatchingStatus.NotMatched,
        }),
    },
    {
        id: LineItemColumnKind.BillBalance,
        kind: LineItemColumnKind.BillBalance,
        name: messages.matchedBillsBalanceName,
        title: messages.matchedBillsBalanceTitle,
        group: ColumnGroup.Matching,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroBillInvoiceLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.Narration,
        kind: LineItemColumnKind.Narration,
        name: messages.narrationColumnName,
        title: messages.narrationColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroManualJournalLine],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.String,
        parseFilter: stringFilter.parseFilter,
        getFilterTransfer: stringFilter.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.TotalDebitAmount,
        kind: LineItemColumnKind.TotalDebitAmount,
        name: messages.totalDebitAmountName,
        title: messages.totalDebitAmountTitle,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroManualJournalLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.TotalCreditAmount,
        kind: LineItemColumnKind.TotalCreditAmount,
        name: messages.totalCreditAmountName,
        title: messages.totalCreditAmountTitle,
        group: ColumnGroup.LineItemField,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroManualJournalLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    },

    {
        id: LineItemColumnKind.ShowOnCashBasisReports,
        kind: LineItemColumnKind.ShowOnCashBasisReports,
        name: messages.showOnCashBasisReportsColumnName,
        title: messages.showOnCashBasisReportsColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroManualJournalLine],
        },
        parseCellValue(value) {
            return value ? messages.yes : messages.no;
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },
    {
        id: LineItemColumnKind.TotalCreditTax,
        kind: LineItemColumnKind.TotalCreditTax,
        name: messages.totalCreditTaxColumnName,
        title: messages.totalCreditTaxColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroManualJournalLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value, 'auto');
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },

    {
        id: LineItemColumnKind.TotalDebitTax,
        kind: LineItemColumnKind.TotalDebitTax,
        name: messages.totalDebitTaxColumnName,
        title: messages.totalDebitTaxColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroManualJournalLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value, 'auto');
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },

    {
        id: LineItemColumnKind.DebitTax,
        kind: LineItemColumnKind.DebitTax,
        name: messages.debitTaxColumnName,
        title: messages.debitTaxColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroManualJournalLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value, 'auto');
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },

    {
        id: LineItemColumnKind.CreditTax,
        kind: LineItemColumnKind.CreditTax,
        name: messages.creditTaxColumnName,
        title: messages.creditTaxColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            lineItemReportCode: [domain.ReportCode.XeroManualJournalLine],
        },
        parseCellValue(value) {
            return intl.formatNumber(value, 'auto');
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    },
];

export default columnLineItemDefinitions;
