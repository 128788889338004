import { Flex, openSplitViewState, ToggleButton } from '@approvalmax/ui/src/components';
import { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { defaultSplitViewOpenState } from 'shared/states';

import { messages } from './PreviewToggleButton.messages';

export const PreviewToggleButton = () => {
    const [openSplitView, setOpenSplitView] = useRecoilState(openSplitViewState);
    const setDefaultSplitViewOpen = useSetRecoilState(defaultSplitViewOpenState);

    const toggleOpenSplitViewState = useCallback(() => {
        setOpenSplitView((currValue) => !currValue);
        setDefaultSplitViewOpen((currValue) => !currValue);
    }, [setOpenSplitView, setDefaultSplitViewOpen]);

    return (
        <Flex inline alignItems='center' shrink={0} grow={0} spacing='16'>
            <ToggleButton.Group
                size='xsmall'
                name='toggleButton'
                value={openSplitView.toString()}
                onChange={toggleOpenSplitViewState}
            >
                <ToggleButton value='true'>{messages.showPreview}</ToggleButton>

                <ToggleButton value='false'>{messages.hidePreview}</ToggleButton>
            </ToggleButton.Group>
        </Flex>
    );
};

PreviewToggleButton.displayName = 'PreviewToggleButton';
