import { Command } from '@approvalmax/types';
import { domain } from 'modules/data';

import { ExpandedCompany } from './Company';
import { ExpandedUser } from './User';

export enum RequestMatchingValidationErrorType {
    MatchingRequired = 'MatchingRequired',
    AtLeastOneMatchingRequired = 'AtLeastOneMatchingRequired',
    OverBudget = 'OverBudget',
    InsufficientBudget = 'InsufficientBudget',
}

export interface RequestMatchingValidationError {
    type: RequestMatchingValidationErrorType;
    errorText: string;
}

export type BaseExpandedRequest = {
    flags: {
        isAuthor: boolean;
        isActiveApprover: boolean;
        isActiveReviewer: boolean;
        isDelegateApprover: boolean;
        isDelegateReviewer: boolean;
        isCompanyManager: boolean;
        /**
         * Indicates whether the request is the actual version (Version History).
         * Default: true
         */
        isActualVersion: boolean;
        status: {
            isOpen: boolean;
            isDraft: boolean;
            isClosed: boolean;
        };
    };
    commands: {
        approve: Command;
        editPrimary: Command;
        completeReview: Command;
        reject: Command;
        revoke: Command;
        forceApprove: Command;
        forceReview: Command;
        forceReject: Command;
        submit: Command;
        editSecondary: Command;
        delete: Command;
        cancel: Command;
        clone: Command;
        reverse: Command;
        clonePOToBill: Command;
        cloneQuoteToSalesInvoice: Command;
        startOver: Command;
        returnToReview: Command;
    };
    majorFraudulentActivityTexts: string[];
    amountInBaseCurrency: number;
    myDecisions: Array<{
        step: domain.RequestStep;
        decision: domain.RequestStepParticipantDecision.Approve | domain.RequestStepParticipantDecision.Reject;
    }>;
    myFinalDecision:
        | domain.RequestStepParticipantDecision.Approve
        | domain.RequestStepParticipantDecision.Reject
        | null;
    activeStep: domain.RequestStep | null;
    author: ExpandedUser;
    company: ExpandedCompany;
};

export type NetSuiteBillExpandedRequest = BaseExpandedRequest & domain.NetSuiteBillRequest;
export type NetSuitePoExpandedRequest = BaseExpandedRequest & domain.NetSuitePoRequest;
export type NetSuiteExpenseReportExpandedRequest = BaseExpandedRequest & domain.NetSuiteExpenseReportRequest;
export type NetSuiteSalesOrderExpandedRequest = BaseExpandedRequest & domain.NetSuiteSalesOrderRequest;
export type NetSuiteVRAExpandedRequest = BaseExpandedRequest & domain.NetSuiteVRARequest;
export type NetSuiteRAExpandedRequest = BaseExpandedRequest & domain.NetSuiteRARequest;
export type NetSuiteBillPaymentExpandedRequest = BaseExpandedRequest & domain.NetSuiteBillPaymentRequest;
export type NetSuiteInvoiceExpandedRequest = BaseExpandedRequest & domain.NetSuiteInvoiceRequest;

export type NetSuiteExpandedRequest =
    | NetSuiteBillExpandedRequest
    | NetSuitePoExpandedRequest
    | NetSuiteExpenseReportExpandedRequest
    | NetSuiteSalesOrderExpandedRequest
    | NetSuiteVRAExpandedRequest
    | NetSuiteRAExpandedRequest
    | NetSuiteBillPaymentExpandedRequest
    | NetSuiteInvoiceExpandedRequest;

export type DearPoExpandedRequest = BaseExpandedRequest & domain.DearPoRequest;
export type DearExpandedRequest = DearPoExpandedRequest;

export type QBooksBillExpandedRequest = BaseExpandedRequest & domain.QBooksBillRequest;
export type QBooksPoExpandedRequest = BaseExpandedRequest & domain.QBooksPoRequest;
export type QBooksInvoiceExpandedRequest = BaseExpandedRequest & domain.QBooksInvoiceRequest;
export type QBooksExpenseExpandedRequest = BaseExpandedRequest & domain.QBooksExpenseRequest;
export type QBooksVendorExpandedRequest = BaseExpandedRequest & domain.QBooksVendorRequest;
export type QBooksJournalEntryExpandedRequest = BaseExpandedRequest & domain.QBooksJournalEntryRequest;
export type QBooksExpandedRequest =
    | QBooksBillExpandedRequest
    | QBooksPoExpandedRequest
    | QBooksInvoiceExpandedRequest
    | QBooksExpenseExpandedRequest
    | QBooksVendorExpandedRequest
    | QBooksJournalEntryExpandedRequest;

export type XeroBillExpandedRequest = BaseExpandedRequest & domain.XeroBillRequest;
export type XeroInvoiceExpandedRequest = BaseExpandedRequest & domain.XeroInvoiceRequest;
export type XeroPoExpandedRequest = BaseExpandedRequest & domain.XeroPoRequest;
export type XeroQuoteExpandedRequest = BaseExpandedRequest & domain.XeroQuoteRequest;
export type XeroContactExpandedRequest = BaseExpandedRequest & domain.XeroContactRequest;
export type XeroBillBatchPaymentExpandedRequest = BaseExpandedRequest & domain.XeroBillBatchPaymentRequest;
export type XeroAirwallexBatchPaymentExpandedRequest = BaseExpandedRequest & domain.XeroAirwallexBatchPaymentRequest;
export type XeroManualJournalExpandedRequest = BaseExpandedRequest & domain.XeroManualJournalRequest;
export type XeroCreditNotesPayableExpandedRequest = BaseExpandedRequest & domain.XeroCreditNotesPayableRequest;
export type XeroCreditNotesReceivableExpandedRequest = BaseExpandedRequest & domain.XeroCreditNotesReceivableRequest;
export type XeroExpandedRequest =
    | XeroBillExpandedRequest
    | XeroInvoiceExpandedRequest
    | XeroPoExpandedRequest
    | XeroQuoteExpandedRequest
    | XeroContactExpandedRequest
    | XeroBillBatchPaymentExpandedRequest
    | XeroAirwallexBatchPaymentExpandedRequest
    | XeroManualJournalExpandedRequest
    | XeroCreditNotesPayableExpandedRequest
    | XeroCreditNotesReceivableExpandedRequest;

export type StandaloneExpandedRequest = BaseExpandedRequest & domain.StandaloneRequest;

export type ExpandedRequest = domain.Request & BaseExpandedRequest;

export enum DueDateWarning {
    LessThanOneDay = 'LessThanOneDay',
    Overdue = 'Overdue',
}

export enum FileType {
    Image = 'Image',
    Pdf = 'Pdf',
    Other = 'Other',
}

export interface ExpandedRequestAttachment extends domain.RequestAttachment {
    extension: string;
    fileType: FileType;
}
