import { atom } from 'recoil';

import { GoBackBarSettingsState } from './GoBackBar.types';

export const goBackBarSettingsState = atom<GoBackBarSettingsState>({
    key: 'goBackBarSettingsState',
    default: {
        show: false,
        contentName: null,
    },
});
