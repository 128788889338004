enum ColumnKind {
    ActiveStepName = 'activeStepName',
    AirwallexBatchPaymentStatus = 'airwallexBatchPaymentStatus',
    AirwallexBatchPaymentTotalAmount = 'airwallexBatchPaymentTotalAmount',
    AmaxPayBankAccountNames = 'amaxPayBankAccountNames',
    AmaxPayBatchPaymentStatus = 'amaxPayBatchPaymentStatus',
    AmaxPayBatchPaymentTotalAmount = 'amaxPayBatchPaymentTotalAmount',
    AmaxPayPaidByUserIds = 'amaxPayPaidByUserIds',
    Amount = 'amount',
    Attachment = 'attachment',
    AuditReportStatus = 'auditReportStatus',
    BillBalance = 'billBalance',
    Branding = 'branding',
    Contact = 'contact',
    CreationDate = 'creationDate',
    Currency = 'currency',
    CustomField = 'customFields',
    Customer = 'customer',
    CustomerDecisionDate = 'customerDecisionDate',
    Date = 'date',
    DearSupplier = 'dearSupplier',
    DeliveryDate = 'deliveryDate',
    Description = 'description',
    DocumentDate = 'documentDate',
    DocumentNumber = 'documentNumber',
    DueDate = 'dueDate',
    ExchangeRate = 'exchangeRate',
    ExpectedDate = 'expectedDate',
    FraudulentActivity = 'fraudulentActivity',
    FullyPaidDate = 'fullyPaidDate',
    GrnStatus = 'grnStatus',
    IsBilled = 'isBilled',
    IsPaid = 'isPaid',
    LineItemAccounts = 'lineItemAccounts',
    LineItemClasses = 'lineItemClasses',
    LineItemContacts = 'lineItemContacts',
    LineItemTrackings1 = 'lineItemTrackings1',
    LineItemTrackings2 = 'lineItemTrackings2',
    Location = 'location',
    MatchedBills = 'matchedBills',
    MatchedPurchaseOrders = 'matchedPurchaseOrders',
    Name = 'name',
    Narration = 'narration',
    NetAmount = 'netAmount',
    NetSuiteCurrency = 'netSuiteCurrency',
    NetSuiteCustomer = 'netSuiteCustomer',
    NetSuiteEmployee = 'netSuiteEmployee',
    NetSuiteExternalStatus = 'netSuiteExternalStatus',
    NetSuiteTerms = 'netSuiteTerms',
    NetSuiteVendor = 'netSuiteVendor',
    NetSuitePayee = 'netSuitePayee',
    ParticipantsApproved = 'participantsApproved',
    ParticipantsNonResponded = 'participantsNonResponded',
    ParticipantsRejected = 'participantsRejected',
    Payers = 'payers',
    PaymentDate = 'paymentDate',
    PurchaseOrderBalance = 'purchaseOrderBalance',
    QBooksCustomField = 'qBooksCustomFields',
    QBooksLineItemAccounts = 'qBooksLineItemAccounts',
    QBooksPayeeCustomer = 'qBooksPayeeCustomer',
    QBooksPayeeEmployee = 'qBooksPayeeEmployee',
    QBooksPaymentAccount = 'qBooksPaymentAccount',
    QBooksPaymentMethod = 'qBooksPaymentMethod',
    QBooksPaymentType = 'qBooksPaymentType',
    QBooksPurchaseOrderStatus = 'qBooksPurchaseOrderStatus',
    QBooksVendorBusinessNumber = 'qBooksVendorBusinessNumber',
    QBooksVendorName = 'qBooksVendorName',
    Reference = 'reference',
    Requesters = 'requesters',
    Resolution = 'resolution',
    ResolutionDate = 'resolutionDate',
    ResolutionTime = 'resolutionTime',
    RetrospectivePurchaseOrder = 'retrospectivePurchaseOrder',
    SentToSupplier = 'sentToSupplier',
    ShippingDate = 'shippingDate',
    ShowOnCashBasisReports = 'showOnCashBasisReports',
    SumOfAllocations = 'sumOfAllocations',
    TaxAmount = 'taxAmount',
    TaxType = 'taxType',
    TemplateId = 'templateId',
    TotalCreditTax = 'totalCreditTax',
    TotalDebitTax = 'totalDebitTax',
    XeroBillBatchPaymentBankAccount = 'xeroBillBatchPaymentBankAccount',
    XeroBillBatchPaymentPaymentDate = 'xeroBillBatchPaymentPaymentDate',
    XeroBillBatchPaymentTotalAmountDue = 'xeroBillBatchPaymentTotalAmountDue',
    XeroContactBankAccountDetails = 'xeroContactBankAccountDetails',
    XeroContactName = 'xeroContactName',
    XeroContactPurchaseTaxCode = 'xeroContactPurchaseTaxCode',
    XeroContactSalesTaxCode = 'xeroContactSalesTaxCode',
    XeroContactTaxNumber = 'xeroContactTaxNumber',
    isAccepted = 'isAccepted',
    isSent = 'isSent',
}

export default ColumnKind;
