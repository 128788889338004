import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.XeroReceiveOverEmailSection', {
    bubbleText: 'Receive by email',
    externalSubmitterRequiredValidationText: 'Please select a Submitter',
    chooseSubmitter: 'Choose a Submitter',
    emailAddressLabel: 'Email address:',
    requestStatusLabel: 'Request status:',
    requestDraftStatusText: 'Draft',
    requestOnApprovalAndReviewStatusText: 'On review/On approval',
    learnMore: 'Learn more',
    copiedToClipboard: 'Copied to clipboard!',
    setupButtonText: 'Setup',
});
