import { Box, Button, Divider, Flex, Text } from '@approvalmax/ui/src/components';
import { ChevronRightIcon, CloseIcon } from '@approvalmax/ui/src/icons';
import { memo, useCallback } from 'react';
import { usePrevious } from 'react-use';
import { useRecoilState } from 'recoil';
import { contentSplitViewState } from 'shared/states';

import { messages } from './GoBackBar.messages';
import { goBackBarSettingsState } from './GoBackBar.states';
import { Root } from './GoBackBar.styles';

export const GoBackBar = memo(() => {
    const [contentSplitView, setContentSplitView] = useRecoilState(contentSplitViewState);
    const prevContentSplitView = usePrevious(contentSplitView);
    const [goBackBarSettings, setGoBackBarSettings] = useRecoilState(goBackBarSettingsState);

    // Update the function only when contentSplitView changes,
    // ensuring the callback always has the latest state value.
    const onGoBack = useCallback(() => {
        prevContentSplitView && setContentSplitView(prevContentSplitView);
        setGoBackBarSettings({ show: false, contentName: null });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentSplitView, setContentSplitView]);

    const onClose = useCallback(() => {
        setGoBackBarSettings({ show: false, contentName: null });
    }, [setGoBackBarSettings]);

    if (!goBackBarSettings.show) {
        return null;
    }

    return (
        <Root inline direction='column' spacing='0'>
            <Box spacing='12 24' color='black100'>
                <Flex inline justifyContent='space-between'>
                    <Flex inline alignItems='center'>
                        <Button icon color='transparent' noPadding onClick={onGoBack}>
                            <ChevronRightIcon size={28} color='white100' />
                        </Button>

                        <Text font='body' ellipsis={1}>
                            {goBackBarSettings.contentName
                                ? messages.goTo({ contentName: goBackBarSettings.contentName })
                                : messages.goBack}
                        </Text>
                    </Flex>

                    <Button icon color='transparent' outline noPadding onClick={onClose}>
                        <CloseIcon color='white100' size={28} />
                    </Button>
                </Flex>
            </Box>

            <Divider color='cobalt60' />
        </Root>
    );
});

GoBackBar.displayName = 'GoBackBar';
