import { selectors } from 'modules/common';
import { getActivePopup } from 'modules/company/selectors/moduleSelectors';
import { domain, State } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { isEnoughDaysAfter } from 'shared/helpers';

import route from '../..';
import {
    sessionKeyWorkflowWizardClosedTime,
    storageKeyWorkflowWizardClosedCount,
    workflowWizardMaxCloseCount,
} from '../constants';

export const useCanShowWorkflowWizardPopup = (): boolean => {
    const matches = useRouteMatch<{ companyId: string }>(route.path);

    const companyId = matches?.params?.companyId;
    const company = useSelector((state) => selectors.company.findCompanyById(state, companyId || ''));
    const standaloneTemplates = useSelector((state: State) =>
        selectors.template.getStandaloneTemplatesByCompanyId(state, companyId ?? '')
    );
    const activePopup = useSelector(getActivePopup);

    const closedCount = useMemo(() => {
        const valueFromStrage = localStorage.getItem(storageKeyWorkflowWizardClosedCount);

        if (!valueFromStrage) {
            return 0;
        }

        return parseInt(valueFromStrage, 10) || 0;
    }, []);

    if (
        !companyId ||
        !company ||
        !company.flags.isManager ||
        company.isReadonly ||
        !company.flags.hasActiveIntegration ||
        activePopup
    ) {
        return false;
    }

    const isXeroOrQbooks =
        company.integration?.integrationType === domain.IntegrationType.Xero ||
        company.integration?.integrationType === domain.IntegrationType.QBooks;

    if (!isXeroOrQbooks) {
        return false;
    }

    if (standaloneTemplates.some((standaloneTemplate) => standaloneTemplate.enabled)) {
        return false;
    }

    if (!isEnoughDaysAfter(2, sessionStorage.getItem(sessionKeyWorkflowWizardClosedTime))) {
        return false;
    }

    return closedCount < workflowWizardMaxCloseCount;
};
