import { Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useCallback, useState } from 'react';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import CollapseAllButton from '../CollapseAllButton/CollapseAllButton';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import SelectRBSubmitter from '../SelectRBSubmitter/SelectRBSubmitter';
import StepSubmitterSection from '../StepSubmitterSection/StepSubmitterSection';
import XeroExternalSubmitterSection from '../XeroExternalSubmitterSection/XeroExternalSubmitterSection';
import { XeroReceiveOverEmailSection } from '../XeroReceiveOverEmailSection/XeroReceiveOverEmailSection';
import { messages } from './XeroBillStartStep.messages';

const XeroBillStartStep = () => {
    const template = useSelector(getActiveTemplate)!;
    const pluralTemplateName = template.displayNamePlural;
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, template.companyId));
    const showSelectRBSubmitter = company.receiptBankIntegration?.isConnected;

    const [collapsedPullingSection, setCollapsedPullingSection] = useState<boolean>();
    const [collapsedReceiveOverEmailSection, setCollapsedReceiveOverEmailSection] = useState<boolean>();
    const [collapsedSubmitterSection, setCollapsedSubmitterSection] = useState<boolean>();
    const [collapsedRbSubmitterSection, setCollapsedRbSubmitterSection] = useState<boolean>();

    const onCollapse = useCallback((collapsed: boolean) => {
        setCollapsedPullingSection(collapsed);
        setCollapsedSubmitterSection(collapsed);
        setCollapsedRbSubmitterSection(collapsed);
        setCollapsedReceiveOverEmailSection(collapsed);
    }, []);

    const allCollapsed = showSelectRBSubmitter
        ? collapsedPullingSection && collapsedSubmitterSection && collapsedRbSubmitterSection
        : collapsedPullingSection && collapsedSubmitterSection;

    const receiveOverEmailSection =
        company.betaFeatures.includes(domain.CompanyBetaFeature.XeroOcr) ||
        company.licenseFeatures.includes(domain.CompanyLicenseFeature.Capture);

    return (
        <FixedStepContainer bubbleText={messages.bubbleText({ pluralTemplateName })}>
            <Text font='body' fontSize='small' color='midnight70'>
                {messages.title({ pluralTemplateName })}
            </Text>

            <CollapseAllButton collapsed={Boolean(allCollapsed)} onCollapse={onCollapse} />

            <XeroExternalSubmitterSection
                collapsed={collapsedPullingSection}
                onCollapse={setCollapsedPullingSection}
                cacheId='XeroBillStartStepExternalSubmitter'
                description={messages.externalSubmitterDescriptionNonEmpty({ pluralTemplateName })}
                descriptionEmpty={messages.externalSubmitterDescriptionEmpty({ templateName: template.displayName })}
                title={messages.externalSubmitterTitle({ pluralTemplateName })}
            />

            <StepSubmitterSection
                cacheId='XeroBillStartStepSubmitter'
                headerText={messages.createInApprovalMax}
                description={messages.description({ pluralTemplateName })}
                descriptionEmpty={messages.descriptionEmpty({ pluralTemplateName })}
                collapsed={collapsedSubmitterSection}
                onCollapse={setCollapsedSubmitterSection}
                required
            />

            {receiveOverEmailSection && (
                <XeroReceiveOverEmailSection
                    collapsed={collapsedReceiveOverEmailSection}
                    onCollapse={setCollapsedReceiveOverEmailSection}
                    cacheId='XeroBillStartStepReceiveOverEmail'
                    description={messages.emailExternaSubmitterDescriptionNonEmpty({ pluralTemplateName })}
                    descriptionEmpty={messages.emailExternalSubmitterDescriptionEmpty({
                        templateName: template.displayName,
                    })}
                    title={messages.emailExternalSubmitterTitle}
                />
            )}

            {showSelectRBSubmitter && (
                <SelectRBSubmitter
                    cacheId='XeroBillStartStepRBSubmitter'
                    collapsed={collapsedRbSubmitterSection}
                    onCollapse={setCollapsedRbSubmitterSection}
                />
            )}
        </FixedStepContainer>
    );
};

export default XeroBillStartStep;
