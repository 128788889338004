import emailConfirmationPageRoute from 'app/(auth)/emailConfirmation';
import loginPageRoute from 'app/(auth)/login';
import registerPageRoute from 'app/(auth)/register';
import resetPassVerifyEmailPageRoute from 'app/(auth)/resetPassVerifyEmail';
import resetPasswordPageRoute from 'app/(auth)/resetPassword';
import verifyEmailPageRoute from 'app/(auth)/verifyEmail';
import amaxPaymentFail from 'app/(public)/amaxPayment/fail';
import amaxPaymentSuccess from 'app/(public)/amaxPayment/success';
import connectBankAccountFail from 'app/(public)/connectBankAccount/fail';
import connectBankAccountSuccess from 'app/(public)/connectBankAccount/success';
import customerDecisionRoute from 'app/(public)/customerDecision';
import mobilePageRoute from 'app/(public)/mobile';
import receiptBankAuth from 'app/(public)/receiptBank/auth';
import reconnectBankAccountSuccess from 'app/(public)/reconnectBankAccount/success';
import bankAccountsRoute from 'app/(workspace)/[companyId]/bankAccounts';
import budgetsRoute from 'app/(workspace)/[companyId]/budgets';
import companyInfoRoute from 'app/(workspace)/[companyId]/companyInfo';
import contactPaymentDetailsRoute from 'app/(workspace)/[companyId]/contactPaymentDetails';
import reportsRoute from 'app/(workspace)/[companyId]/reports';
import reportsLineitemRoute from 'app/(workspace)/[companyId]/reports/lineItem';
import reportLineItemPageRoute from 'app/(workspace)/[companyId]/reports/lineItem/[reportId]';
import reportsRequestRoute from 'app/(workspace)/[companyId]/reports/request';
import reportPageRoute from 'app/(workspace)/[companyId]/reports/request/[reportId]';
import usersRoute from 'app/(workspace)/[companyId]/users';
import workflowsRoute from 'app/(workspace)/[companyId]/workflows';
import workflowsIdRoute from 'app/(workspace)/[companyId]/workflows/[workflowId]';
import workflowsNewRoute from 'app/(workspace)/[companyId]/workflows/new';
import completeSetupRoute from 'app/(workspace)/completeSetup';
import customerFeedbackRoute from 'app/(workspace)/customerFeedback';
import customerFeedbackBoardIdRoute from 'app/(workspace)/customerFeedback/[boardId]';
import noCompaniesRoute from 'app/(workspace)/noCompanies';
import globalRoutes from 'pages/@global/routes';
import authRoutesOrigin from 'pages/auth/routes';
import customFieldsRoutes from 'pages/customFields/routes';
import noCompaniesRoutes from 'pages/noCompanies/routes';
import reportsRoutes from 'pages/reports/routes';
import requestFormRoutes from 'pages/requestForm/routes';
import requestListRoutes from 'pages/requestList/routes';
import twoFaRoutes from 'pages/twoFaHardEnforcement/routes';

/**
 * All auth routes
 */
export const authRoutes = [...authRoutesOrigin, ...twoFaRoutes];

/**
 * All auth redesigned routes
 */
export const authRoutesRedesign = [
    loginPageRoute,
    registerPageRoute,
    emailConfirmationPageRoute,
    resetPasswordPageRoute,
    resetPassVerifyEmailPageRoute,
    verifyEmailPageRoute,
    mobilePageRoute,
];

/**
 * All unauthorized routes
 */
export const publicRoutes = [
    connectBankAccountSuccess,
    connectBankAccountFail,
    reconnectBankAccountSuccess,
    amaxPaymentFail,
    amaxPaymentSuccess,
];

/**
 * All unauthorized redesigned routes
 */
export const publicRoutesRedesign = [
    customerDecisionRoute,
    connectBankAccountSuccess,
    connectBankAccountFail,
    reconnectBankAccountSuccess,
    amaxPaymentFail,
    amaxPaymentSuccess,
    receiptBankAuth,
];

/**
 * All workspace routes
 */
export const workspaceRoutes = [
    ...globalRoutes,
    ...customFieldsRoutes,
    ...noCompaniesRoutes,
    ...reportsRoutes,
    ...requestFormRoutes,
    ...requestListRoutes,
    bankAccountsRoute,
    contactPaymentDetailsRoute,
];

/**
 * All workspace redesigned routes
 */
export const workspaceRoutesRedesign = [
    budgetsRoute,
    workflowsRoute,
    workflowsNewRoute,
    workflowsIdRoute,
    usersRoute,
    companyInfoRoute,
    reportsRoute,
    reportsRequestRoute,
    reportsLineitemRoute,
    reportPageRoute,
    reportLineItemPageRoute,
    customerFeedbackRoute,
    customerFeedbackBoardIdRoute,
    noCompaniesRoute,
    completeSetupRoute,
];
