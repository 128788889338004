import { CircleFilledIcon, MatrixIcon } from '@approvalmax/ui';
import { Box, Button, Collapse, Flex, Text, Tooltip } from '@approvalmax/ui/src/components';
import { arrayHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addSubmitter, addTempUser, openInviteUsersPopup, openSubmitterMatrix, removeSubmitter } from '../../actions';
import { getActiveTemplate, isPageReadonly, showCardValidationErrors } from '../../selectors/pageSelectors';
import { getTemplateSubmitters, isMatrixUnavailable } from '../../selectors/templateSelectors';
import { ExpandedTemplateUser } from '../../types/selectors';
import { addUserEvent, removeUserEvent } from '../../utils/events';
import { createUserComparator } from '../../utils/helpers';
import AddUserButton from '../AddUserButton/AddUserButton';
import StepUser from '../StepUser/StepUser';
import { messages } from './StepSubmitterSection.messages';
import { StepSubmitterSectionProps } from './StepSubmitterSection.types';

const StepSubmitterSection: FC<StepSubmitterSectionProps> = memo((props) => {
    const { collapsed, onCollapse, description, descriptionEmpty, cacheId, headerText, required, noMatrix } = props;

    const dispatch = useDispatch();
    const template = useSelector(getActiveTemplate)!;
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, template.companyId));
    const readonly = useSelector(isPageReadonly);
    const matrixUnavailable = useSelector(isMatrixUnavailable);
    const team = useSelector((state: State) => selectors.company.getCompanyTeam(state, company));
    const submitters = useSelector((state: State) => getTemplateSubmitters(state, company.id));
    const hasAnySubmitters = useSelector((state: State) =>
        selectors.template.getTemplateHasAnySubmitters(state, template)
    );
    const showValidationErrors = useSelector(showCardValidationErrors);

    const onAddTempUser = useCallback(
        (value: domain.User) => {
            dispatch(addTempUser(value));

            addUserEvent('requester', template.integrationCode, true);
        },
        [dispatch, template.integrationCode]
    );

    const onInviteUser = useCallback(
        (user: ExpandedTemplateUser) => {
            dispatch(openInviteUsersPopup([user.id]));
        },
        [dispatch]
    );

    const onAddSubmitter = useCallback(
        (user: domain.User) => {
            dispatch(addSubmitter(user.id));

            if (user.databaseId) {
                addUserEvent('requester', template.integrationCode);
            }
        },
        [dispatch, template.integrationCode]
    );

    const onRemoveSubmitter = useCallback(
        (user: domain.User) => {
            dispatch(removeSubmitter(user.id, user.databaseId));

            removeUserEvent('requester', template.integrationCode);
        },
        [dispatch, template.integrationCode]
    );

    const onOpenSubmitterMatrix = useCallback(() => {
        if (matrixUnavailable || noMatrix) return;
        dispatch(openSubmitterMatrix());
    }, [dispatch, matrixUnavailable, noMatrix]);

    const onUserOpenSubmitterMatrix = useCallback(
        (userId: string) => {
            if (matrixUnavailable || noMatrix) return;
            dispatch(openSubmitterMatrix(userId));
        },
        [dispatch, matrixUnavailable, noMatrix]
    );

    const invalid = required && showValidationErrors && !hasAnySubmitters && !submitters.length;

    const collapseColor = useMemo(() => {
        if (readonly) return 'silver80';

        if (invalid) return 'red30';

        return undefined;
    }, [invalid, readonly]);

    const sortedSubmitters = useMemo(
        () => arrayHelpers.arraySort<ExpandedTemplateUser>(submitters, createUserComparator(team)),
        [submitters, team]
    );

    return (
        <Collapse
            name={headerText}
            spacing='0 8'
            localStorageKey={cacheId}
            radius='xsmall'
            alignCollapseIcon='right'
            font='label'
            size='small'
            fontWeight='regular'
            onCollapse={onCollapse}
            collapsed={collapsed && !invalid}
            bordered
            startIcon={
                submitters.length > 0 ? (
                    <CircleFilledIcon size={12} color={readonly ? 'silver100' : 'green80'} />
                ) : undefined
            }
            headerContent={
                <Text font='body' fontSize='small' color='midnight100'>
                    {submitters.length === 0 ? descriptionEmpty || description : description}
                </Text>
            }
            color={collapseColor}
        >
            <Flex spacing='8' height={40} inline alignItems='center' wrap={false}>
                <Tooltip
                    open={invalid && !collapsed}
                    color='cobalt100'
                    activator={
                        <AddUserButton
                            users={team}
                            excludedUsers={submitters}
                            disabled={readonly}
                            onAddNew={onAddTempUser}
                            onSelect={onAddSubmitter}
                            title={messages.addRequester}
                            type='step'
                            invalid={invalid}
                        />
                    }
                >
                    {messages.selectRequester}
                </Tooltip>

                {!noMatrix && (
                    <Button
                        icon
                        noPadding
                        onClick={onOpenSubmitterMatrix}
                        disabled={matrixUnavailable}
                        title={messages.openMatrixTitle}
                    >
                        <MatrixIcon size={20} color={matrixUnavailable ? 'midnight80' : 'blue80'} />
                    </Button>
                )}
            </Flex>

            {submitters.length > 0 && (
                <Box spacing='8 0'>
                    <Flex inline direction='column' spacing='8'>
                        {sortedSubmitters.map((submitter) => (
                            <StepUser
                                team={team}
                                key={submitter.id}
                                user={submitter}
                                isCompanyMember={team.some((t) => t.id === submitter.id)}
                                onRemove={onRemoveSubmitter}
                                readonly={readonly}
                                onInvite={onInviteUser}
                                onOpenMatrix={matrixUnavailable || noMatrix ? undefined : onUserOpenSubmitterMatrix}
                            />
                        ))}
                    </Flex>
                </Box>
            )}
        </Collapse>
    );
});

export default StepSubmitterSection;
