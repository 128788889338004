import type { ApiError, ApiVersion } from '@approvalmax/types';
import { routerHelpers } from '@approvalmax/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useApiContext } from '../ApiContext/ApiContext';
import { HEADER_SESSION_EXPIRATION_DATE, HEADER_WEB_APP_VERSION } from '../ApiContext/ApiContext.constants';
import { Env } from '../configs';
import { getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import rootApi from '../rootApi';
import { ApiBaseAnswer, ApiMethods, ApiSource, MutationOptions, RequestDataParams } from '../types';

export const createUseMutate = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    const useMutate = <
        RequestData extends RequestDataParams<Record<string, any>, Record<string, string> | undefined>,
        ResponseData extends Record<string, any> = ApiBaseAnswer,
        MappedResponseData = ResponseData,
    >(
        path: string,
        options: {
            mapData?: (data: ResponseData) => MappedResponseData;
            apiSource?: ApiSource;
            apiVersion?: ApiVersion;
            method?: Exclude<ApiMethods, 'get'>;
            mutationOptions?: MutationOptions<MappedResponseData, RequestData>;
        } = {}
    ) => {
        const { apiSource = defaultApiSource, apiVersion = 'v1', method = 'post', mutationOptions, mapData } = options;
        const queryClient = useQueryClient();
        const apiUrl = getApiUrl(apiSource, defaultEnv, apiVersion);
        const Authorization = getAuthorization(defaultApiSource, defaultEnv);
        const { setApiError, setSessionExpirationDate, setWebAppVersion } = useApiContext();

        return useMutation<MappedResponseData, ApiError, RequestData>(
            async ({ data, pathParams, getParams = {} }) => {
                const url = routerHelpers.pathToUrl(path, pathParams);

                const isFormData = data instanceof FormData;
                const contentTypeHeader = { 'Content-Type': isFormData ? 'multipart/form-data' : 'application/json' };

                return rootApi({
                    baseURL: apiUrl,
                    method,
                    params: getParams,
                    url,
                    headers: {
                        ...(Authorization && { Authorization }),
                        ...contentTypeHeader,
                    },
                    data,
                })
                    .then((res: AxiosResponse<ResponseData & ApiBaseAnswer>): MappedResponseData => {
                        const newSessionExpirationDate = res.headers[HEADER_SESSION_EXPIRATION_DATE];

                        if (newSessionExpirationDate) {
                            setSessionExpirationDate(newSessionExpirationDate);
                        }

                        const newWebAppVersion = res.headers[HEADER_WEB_APP_VERSION];

                        if (newWebAppVersion) {
                            setWebAppVersion(newWebAppVersion);
                        }

                        return mapData ? mapData(res.data) : (res.data as MappedResponseData);
                    })
                    .catch((error: ApiError) => {
                        setApiError(error);

                        throw error;
                    });
            },
            {
                ...mutationOptions,
                onSuccess: async (data, variables, context) => {
                    const url = routerHelpers.pathToUrl(path, variables.pathParams);

                    await queryClient.invalidateQueries([url]);
                    mutationOptions?.onSuccess?.(data, variables, context);
                },
            }
        );
    };

    return useMutate;
};
